import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { marked } from "marked";
import { useThemes } from "../../../UserContext/UserContext";
import SpinnserData from "../Pages/SpinnerData";


const VoyageVisitorPublier = () => {
    const { isDark, mobileTop } = useThemes()
    const [formData, setFormData] = useState({
        titre: "",
        description: "",
        category: "",
        images: null,
        lieu: "",
    });
    const [markdown, setMarkdown] = useState(""); // État pour le Markdown
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const [loggedIn, SetIsLogin] = useState(false);
    const [isSpinnerData, SetIsSpinnerData] = useState(true)

    useEffect(() => {
        const checkLoggedInUser = async () => {
            try {
                const token = localStorage.getItem("accessToken");
                if (token) {
                    const config = { headers: { Authorization: `Bearer ${token}` } };
                    await axios.get(
                        "https://ttcvbackend.eac-advisor.org/accounts/user/",
                        config
                    );
                    SetIsLogin(true);
                    SetIsSpinnerData(false)
                } else {
                    navigate("/");
                }
            } catch {
                navigate("/");
            }
        };
        checkLoggedInUser();
    }, [navigate]);

    const [nomImage, SetNomImage] = useState('')
    const [imagePreview, SetimagePreview] = useState('')
    const handle = (e) => {
        const { name, value, type, files } = e.target;
        if (files) {
            SetNomImage(files[0].name)
            SetimagePreview(URL.createObjectURL(files[0]))
        }

        setFormData({
            ...formData,
            [name]: type === "file" ? files[0] : value,
        });
        if (name === "description") {
            // Mettez à jour le Markdown pour la description
            setMarkdown(value);
        }
    };

    const validateForm = () => {
        if (
            !formData.titre ||
            !markdown ||
            !formData.category ||
            !formData.lieu ||
            !formData.images
        ) {
            return "Tous les champs doivent être remplis.";
        }
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!loggedIn) {
            setError("Vous devez être connecté pour publier.");
            return;
        }

        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }

        setIsLoading(true);
        setError(null);
        setSuccess(false);
        const token = localStorage.getItem("accessToken");

        try {
            const data = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                data.append(key, value);
            });
            data.append("description", markdown); // Ajoute le Markdown au FormData

            await axios.post(
                "https://ttcvbackend.eac-advisor.org/posts/posts/",
                data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setSuccess(true);
            setFormData({
                titre: "",
                description: "",
                category: "",
                images: null,
                lieu: "",
            });
            setMarkdown(""); // Réinitialiser le Markdown
            navigate("/");
        } catch (error) {
            const errorMessage = error.response?.data?.detail || "Erreur inconnue.";
            setError(`Erreur: ${errorMessage}`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <section className={`w-full  ${isDark === "dark" ? null : 'bg-gray-100'} ${mobileTop ? 'mt-[6vh]' : 'mt-[12vh]'}  p-2  relative  md:max-auto `}>
            {isSpinnerData && <SpinnserData />}
            <div className="flex justify-end rounded-md items-center w-full">
                <div onClick={() => navigate('/voyage')} className="bg-blue-700 text-white cursor-pointer p-1 w-max">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg>
                </div>
            </div>
            <div className="h-full w-full  md:flex md:items-center relative shadow-md space-x-2 md:space-x-12 md:justify-center mt-14 border-4 border-dotted border-y-slate-300">
                <form
                    onSubmit={handleSubmit}
                    className="flex items-center justify-center sm:p-10 shadow-lg rounded-lg flex-col space-y-2 border border-slate-300 w-full h-full"
                    encType="multipart/form-data"
                >

                    <div className="flex p-5 justify-center shadow-lg rounded-lg flex-col  border border-slate-300 w-full h-full">
                        <div className="font-bold text-2xl my-3">Publication d'un Article</div>
                        <label htmlFor="titre" className="font-bold text-xl">
                            Titre d'article
                        </label>
                        <input
                            type="text"
                            name="titre"
                            value={formData.titre}
                            onChange={handle}
                            placeholder="Votre titre..."
                            className={`border-2  border-blue-500 mb-5 block w-full p-3  bg-transparent rounded-md focus:outline-none outline-none ${isDark === "dark" ? 'text-gray-100' : 'text-gray-900'} focus:border-blue-400`}
                            required
                        />
                        <label htmlFor="lieu" className="font-bold">
                            Ajouter un lieu (pays + ville)
                        </label>
                        <input
                            type="text"
                            name="lieu"
                            value={formData.lieu}
                            onChange={handle}
                            placeholder="Entrer le lieu d'article..."
                            className={`border-2 mb-5  border-blue-500 w-full p-3  bg-transparent rounded-md focus:outline-none outline-none ${isDark === "dark" ? 'text-gray-100' : 'text-gray-900'} focus:border-blue-400`}
                            required
                        />
                        <label htmlFor="images"
                            className={`border-2 mb-5 cursor-pointer border-blue-500 w-full p-3  bg-transparent rounded-md focus:outline-none outline-none ${isDark === "dark" ? 'text-gray-100' : 'text-gray-900'} focus:border-blue-400`}
                        >
                            Ajouter une image:
                        </label>
                        <input
                            type="file"
                            name="images"
                            id="images"
                            onChange={handle}
                            hidden
                            className="px-8 py-1 mb-5  outline-none focus:border-2 focus:border-green-500"
                            required
                        />

                        <div className="w-[10em] mb-2 rounded-md border  overflow-hidden h-[10em]">
                            <div>{nomImage}</div>
                            <div>
                                <img className="w-full h-[8em] object-contain object-center" src={`${imagePreview}`} alt="    " />
                            </div>
                        </div>

                        <label htmlFor="description" className="font-bold">
                            Description d'article (Markdown):
                        </label>
                        <textarea
                            placeholder="Ajouter une description en Markdown..."
                            value={markdown}
                            name="description"
                            onChange={handle}
                            className={`border-2 border-blue-500 mb-5  w-full  bg-transparent rounded-md focus:outline-none outline-none focus:border-blue-400 min-h-[10em] max-h-[20em] ${isDark === "dark" ? 'text-gray-100' : 'text-gray-900'} p-3`}
                            required
                        ></textarea>
                        <div
                            className="border p-4 rounded-lg mb-4 prose"
                            dangerouslySetInnerHTML={{ __html: marked(markdown) }}
                        />{" "}
                        {/* Aperçu Markdown */}
                        <div className="font-bold">
                            Choisir la catégorie de votre publication :
                        </div>
                        <div className="flex font-bold  items-center space-x-7">
                            {["CULTURE", "VOYAGE", "TOURISME"].map((category) => (
                                <label key={category}>
                                    <input
                                        type="radio"
                                        name="category"
                                        value={category}
                                        checked={formData.category === category}
                                        onChange={handle}
                                    />
                                    {category}
                                </label>
                            ))}
                        </div>
                        <button
                            type="submit"
                            className="px-28 text-white py-3 bg-slate-500"
                            disabled={isLoading}
                        >
                            {isLoading ? "Publication en cours..." : "Publier"}
                        </button>
                        {error && <p className="text-red-500">{error}</p>}
                        {success && <p className="text-green-500">Publication réussie!</p>}

                    </div>
                </form>
            </div>
        </section>
    );
};

export default VoyageVisitorPublier;
