import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const UserContext = createContext();
export function ContextPovider({ children }) {
  // .....................crypte.......................
  const [isDeskTop, SetIsDeskTop] = useState(window.innerWidth > 1315);

  const [isVisitor, SetIsVisitor] = useState(true);
  const [isAdmin, SetIsAdmin] = useState(false);
  const [isGoingToSite, SetisGoingToSite] = useState(false);


  const [isMobileSlide, SetIsMobileSlide] = useState(window.innerWidth < 526);
  const [mobileTop, setMobileTop] = useState(window.innerWidth < 1098)
  useEffect(() => {
    const hundleSizeSilde = () => {
      setMobileTop(window.innerWidth < 1098)
      SetIsMobileSlide(window.innerWidth < 526)
      SetIsDeskTop(window.innerWidth > 1315)
    }

    window.addEventListener('resize', hundleSizeSilde)
    return () => {
      window.removeEventListener('resize', hundleSizeSilde)
    }
  }, [])

  // ...............................scroll window......................
  const [getWidth, SetgetWidth] = useState(0);
  const [isScroll, SetisScroll] = useState(false);

  useEffect(() => {
    const hundleScroll = (e) => {
      const value = window.pageYOffset;
      if (value > 100) {
        SetisScroll(true);
      } else {
        SetisScroll(false);
      }
      SetgetWidth(
        value /
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight)
      );
    };

    window.addEventListener("scroll", hundleScroll);

    return () => {
      window.removeEventListener("scroll", hundleScroll);
    };
  }, []);

  const HundlwScrollTop = () => {
    document.documentElement.scrollTop = 0;
    document.documentElement.style.scrollBehavior = "smooth";
  };

  const triggerRef = useRef();
  const close = () => triggerRef.current && triggerRef.current.close();

  //visiteur avec evenement onClick
  const [isDark, setIsDark] = useState("light");
  const toggleDarkMode = () => {
    const newDarkMode = isDark === "dark" ? "light" : "dark";
    setIsDark(newDarkMode);
    localStorage.setItem("isDark", newDarkMode);
  };

  useEffect(() => {
    const storedValue = localStorage.getItem("isDark");
    if (storedValue) {
      setIsDark(storedValue);
    }
  }, []);

  const [isDarVisitLoggined, GetisDarVisitLoggined] = useState("light");
  const handleChange = (value) => {
    GetisDarVisitLoggined(value);
    close();
    localStorage.setItem("isDark", value);
  };

  useEffect(() => {
    const storedValue = localStorage.getItem("isDark");
    if (storedValue) {
      GetisDarVisitLoggined(storedValue);
    }
  }, [isDark]);



  const [openModal, SetOpenModal] = useState(false);

  const [detailEvenement, SetDetailEvenement] = useState({});
  // ............................login................
  const [isLogin, SetIsLogin] = useState(false);
  const [openRegi, setOpenRegi] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [reset, setrest] = useState(false);
  const [openforget, setopenforget] = useState();
  const [username, setUsername] = useState("");

  // ................Pour admin seulement..........................
  const [mobile, SetMobile] = useState(window.innerWidth < 1107)
  
  useEffect(() => {
    const hundleSizeSilde = () => {
      SetMobile(window.innerWidth < 1107)
    }
    window.addEventListener('resize', hundleSizeSilde)
    return () => window.removeEventListener('resize', hundleSizeSilde)

  }, [])
  return (
    <UserContext.Provider
      value={{
        username, setUsername,
        openRegi, setOpenRegi,
        openLogin, setOpenLogin,
        reset, setrest,
        openforget, setopenforget,
        isDeskTop, SetIsDeskTop,
        isMobileSlide,
        detailEvenement,
        isLogin,
        SetIsLogin,
        SetDetailEvenement,
        openModal,
        SetOpenModal,
        handleChange,
        triggerRef,
        isDarVisitLoggined,
        isDark,
        toggleDarkMode,
        isGoingToSite,
        SetisGoingToSite,
        HundlwScrollTop,
        getWidth,
        isScroll,
        isVisitor,
        SetIsVisitor,
        isAdmin,
        SetIsAdmin,
        mobileTop,
        // ..........admin................
        mobile
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useThemes = () => {
  return useContext(UserContext);
};
