import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";

const UtilisateurAdmin = () => {
    const [isloading, setisloading] = useState(true);
    const [error, setError] = useState(null);
    const { isDark } = useThemes()
    const [data, setdata] = useState([]);

    useEffect(() => {
        const Listusers = async () => {
            try {
                const response = await axios.get(
                    "https://ttcvbackend.eac-advisor.org/accounts/membres"
                );
                setdata(response.data);
            } catch (error) {
                console.error("Erreur de recuperation des utilisateurs", error);
                setError("Erreur de recuperation des utilisateurs");
            } finally {
                setisloading(false);
            }
        };
        Listusers();
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const itemsPerPage = 20; // Nombre d'articles par page

    // Filtrer les données en fonction du terme de recherche
    const filteredData = data.filter((item) =>
        item.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Calculer les indices pour les éléments à afficher
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Changer de page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Nombre total de pages
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    return (
        <section className={`w-full ${isDark === "dark" ? null : 'bg-gray-100'} p-2 relative md:max-auto`}>
            <div>
                <div className="font-bold m-4 md:text-2xl">
                    La gestion des utilisateurs est ici :
                </div>

                {/* Champ de recherche */}
                <input
                    type="text"
                    placeholder="Rechercher par nom d'utilisateur ou prénom..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(1); // Réinitialiser à la première page lors d'une recherche
                    }}
                    className="mb-4 p-2 border bg-transparent rounded"
                />

                <div className="overflow-y-hidden overflow-x-auto">
                    <table className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} border border-black w-full h-full`}>
                        <thead>
                            <tr className="bg-blue-500 text-white uppercase">
                                <th className="p-3 text-nowrap  text-left">ID</th>
                                <th className="p-3 text-nowrap  text-left">Username</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((user) => (
                                    <tr
                                        key={user.id}
                                        className="border-b border-gray-400 transition-all hover:text-black hover:bg-gray-200"
                                    >
                                        <td className="p-3 text-nowrap  text-left border-r border-gray-400 ">
                                            {user.id}
                                        </td>
                                        <td className="p-3 text-nowrap  text-left border-r border-gray-400 text-blue-500 underline">
                                            <Link to={`${user.id}`}>{user.username}</Link>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="py-3 text-center">
                                        Aucun utilisateur trouvé
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => paginate(index + 1)}
                            className={`mx-1 px-4 py-2 border rounded ${currentPage === index + 1
                                ? "bg-blue-500 text-white"
                                : "bg-white text-blue-500"
                                }`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default UtilisateurAdmin;
