import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
import {
    FaRegEye,
    FaRegComment,
    FaFacebook,
    FaWhatsapp,
    FaArrowRight,
} from "react-icons/fa6";
import { AiFillLike, AiOutlineLike } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa6";
import { MdBusinessCenter } from "react-icons/md";
import { IoLocation } from "react-icons/io5";
import ReactMarkdown from "react-markdown";
import { format } from "date-fns";
import { GoClock } from "react-icons/go";
import { useThemes } from "../../../UserContext/UserContext";
import SpinnserData from "../Pages/SpinnerData";


const VoyageVisitorDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { isDark, HundlwScrollTop, mobileTop } = useThemes()
    const [posts, setPosts] = useState({});
    const [notes, setNotes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [likesCount, setLikesCount] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [viewsCount, setViewsCount] = useState(0);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState({ content: "" });
    const [displayCount, setDisplayCount] = useState(1);
    const [success, setSuccess] = useState(false);

    const handle = (e) => {
        setNewComment({ ...newComment, [e.target.name]: e.target.value });
    };

    const [isSpinnerData, SetIsSpinnerData] = useState(true)
    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(
                    `https://ttcvbackend.eac-advisor.org/posts/posts/${id}`
                );
                setPosts(response.data);
                SetIsSpinnerData(false)
            } catch (error) {
                console.error("Erreur lors de la récupération des données", error);
                setError("Erreur de chargement");
            } finally {
                setIsLoading(false);
            }
        };
        fetchPost();
    }, [id]);

    useEffect(() => {
        const fetchPopularPosts = async () => {
            try {
                const response = await axios.get(
                    "https://ttcvbackend.eac-advisor.org/posts/posts"
                );
                const filteredPosts = response.data.filter(
                    (item) => item.is_valid && !item.is_deleted
                );
                setNotes(filteredPosts.slice(0, 3));
                SetIsSpinnerData(false)
            } catch (error) {
                console.error("Erreur lors de la récupération des données", error);
                setError("Erreur de chargement");
            }
        };
        fetchPopularPosts();
    }, []);

    useEffect(() => {
        const fetchLikeData = async () => {
            try {
                const response = await axios.get(
                    `https://ttcvbackend.eac-advisor.org/posts/posts/${id}/`
                );
                setLikesCount(response.data.likes_count || 0);
                setIsLiked(response.data.is_liked_by_user);
                SetIsSpinnerData(false)
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des données de like",
                    error
                );
            }
        };
        fetchLikeData();
    }, [id]);

    const handleLike = async () => {
        try {
            const token = localStorage.getItem("accessToken");
            await axios.post(
                `https://ttcvbackend.eac-advisor.org/posts/posts/${id}/like/`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setIsLiked(!isLiked);
            setLikesCount(isLiked ? likesCount - 1 : likesCount + 1);
        } catch (error) {
            console.error("Erreur lors de l'ajout d'un like", error);
        }
    };

    useEffect(() => {
        const registerView = async () => {
            try {
                const response = await axios.get(
                    `https://ttcvbackend.eac-advisor.org/posts/posts/${id}/view/`
                );
                setViewsCount(response.data.views_count);
                SetIsSpinnerData(false)
            } catch (error) {
                console.error("Erreur lors de l'enregistrement de la vue", error);
            }
        };
        registerView();
    }, [id]);

    useEffect(() => {
        const fetchCommentaires = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `https://ttcvbackend.eac-advisor.org/posts/publications/${id}/commentaires/`
                );
                SetIsSpinnerData(false)
                setComments(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des commentaires", error);
                setError("Erreur lors de la récupération des commentaires");
            } finally {
                setIsLoading(false);
            }
        };
        fetchCommentaires();
    }, [id]);

    const addComment = async (e) => {
        e.preventDefault();
        if (!newComment.content) {
            setError("Le commentaire ne peut pas être vide.");
            return;
        }
        setSuccess(false);
        setError(null);

        const token = localStorage.getItem("accessToken");
        try {
            const response = await axios.post(
                `https://ttcvbackend.eac-advisor.org/posts/publications/${id}/commentaires/`,
                { content: newComment.content },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setSuccess(true);
            setComments([...comments, response.data]);
            setNewComment({ content: "" }); // Reset comment field
        } catch (error) {
            console.error("Erreur de publication", error);
            const errorMessage =
                error.response?.data.detail || JSON.stringify(error.response?.data);
            setError(`Erreur: ${errorMessage}`);
        }
    };

    const handleViewMore = () => {
        setDisplayCount(displayCount + 4);
    };

    const handleViewLess = () => {
        setDisplayCount(Math.max(4, displayCount - 4));
    };

    const handleDeleteComment = async (commentId) => {
        try {
            const token = localStorage.getItem("accessToken");
            await axios.delete(
                `https://ttcvbackend.eac-advisor.org/posts/publications/${id}/commentaires/${commentId}/`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setComments(comments.filter((comment) => comment.id !== commentId));
            alert("Commentaire supprimé avec succès !");
        } catch (error) {
            console.error("Erreur de suppression", error);
            const errorMessage =
                error.response?.data.detail || JSON.stringify(error.response?.data);
            setError(`Erreur: ${errorMessage}`);
        }
    };

    const dateValue = Date.parse(posts.created);
    const formattedDate = dateValue
        ? format(new Date(dateValue), "dd MMMM yyyy")
        : "Date invalide";



    // Fonction utilitaire pour calculer la différence en jours
    const calculateDaysSinceCreation = (creationDate) => {
        const createdDate = new Date(creationDate); // Convertit la date de création en objet Date
        const currentDate = new Date(); // Date actuelle

        // Calcul de la différence en millisecondes
        const differenceInMs = currentDate - createdDate;

        // Convertit la différence en jours
        const daysDifference = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

        return daysDifference;
    };


    return (
        <section className={`w-full relative ${mobileTop ? 'mt-[6vh]' : 'mt-[12vh]'}  ${isDark === "dark" ? null : 'bg-gray-100'} p-2  md:max-auto `}>
            {isSpinnerData && <SpinnserData />}
            <div className="flex justify-end rounded-md items-center w-full">
                <div onClick={() => navigate('/voyage')} className="bg-blue-700 text-white cursor-pointer p-1 w-max">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg>
                </div>
            </div>
            <div className="sm:ml-4">
                <div className={`font-bold text-lg ${isDark === "dark" ? 'text-white' : 'text-black'} sm:text-2xl`}>{posts.titre}</div>
                <p>{posts.category}</p>

                <div className={` w-60 p-2 ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} rounded-lg sm:p-4`}>
                    <div className="flex items-center space-x-4">
                        <p className="font-bold flex items-center">
                            <FaRegUser />
                            {posts.username}
                        </p>
                        <p className="flex items-center">
                            <MdBusinessCenter />
                            {posts.profession}
                        </p>
                    </div>
                    <p className="italic flex items-center">
                        <IoLocation />
                        {posts.lieu}
                    </p>
                </div>
                <p className="italic flex items-center">
                    <GoClock />
                    {formattedDate}
                </p>

                {/* Image avec style amélioré */}
                <img
                    className="w-full h-[60vh] object-cover rounded-lg shadow-lg mt-4"
                    src={posts.images}
                    alt={posts.titre}
                />

                {/* Description avec ReactMarkdown */}
                <div className={`border-l-2 p-2 border-blue-500 w-full ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'}   md:w-full border-r-2  mt-3 sm:p-4 rounded-lg  shadow-md`}>
                    <ReactMarkdown className="prose prose-slate sm:text-xl">
                        {posts.description}
                    </ReactMarkdown>
                </div>

                <div className="flex items-center md:space-x-40 space-x-3 mt-12 mb-12">
                    <button
                        onClick={handleLike}
                        className={`p-2 text-blue-600 ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'}  rounded-lg flex items-center`}
                    >
                        {isLiked ? <AiOutlineLike size={24} /> : <AiFillLike size={24} />}{" "}
                        {likesCount}
                    </button>
                    <p className={`flex text-blue-600 items-center space-x-3 ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'}  p-2 rounded-lg`}>
                        <FaRegEye />
                        {viewsCount}
                    </p>
                    <p className={`flex text-blue-600 items-center space-x-3 ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'}  p-2 rounded-lg`}>
                        <FaRegComment />
                        {comments.length}
                    </p>
                </div>

                <div className="w-full">
                    <form className="w-full  space-x-3" onSubmit={addComment}>
                        <textarea
                            value={newComment.content}
                            onChange={handle}
                            placeholder="Ajouter un commentaire..."
                            name="content"
                            className={`p-2  rounded-lg w-full min-h-[10em]  max-h-[20em] bg-transparent ${isDark === "dark" ? 'text-white ' : 'text-black border-gray-700'} border focus:border-blue-500 outline-none`}
                        />
                        <div className="w-full flex justify-end pr-2">
                            <button
                                type="submit"
                                className="bg-blue-500 px-3 border rounded-md hover:scale-105 border-blue-500 outline-none py-2 text-white"
                            >
                                Envoyer
                            </button>
                        </div>

                    </form>
                    {error && <p className="text-red-500">{error}</p>}
                </div>
                <div className="font-bold">Commentaires : {comments.length}</div>

                <div>
                    <div className={`md:w-96 w-full h-full p-3  ${comments.length == 0 ? '' : 'border border-slate-300'}  rounded-lg`}>
                        {comments.slice(0, displayCount).map((value) => (
                            <div key={value.id}>
                                <div className="flex items-center space-x-10">
                                    <h1 className="font-bold flex items-center">
                                        <FaRegUser />
                                        {value.username}
                                    </h1>
                                    <p className="italic">{calculateDaysSinceCreation(value.created)} jours</p>

                                </div>
                                <p className="w-full break-words italic">{value.content}</p>
                                <button
                                    type="button"
                                    className="text-blue-500 underline"
                                    onClick={() => handleDeleteComment(value.id)}
                                >
                                    supprimer
                                </button>
                            </div>
                        ))}
                    </div>
                    {displayCount < comments.length && (
                        <button
                            onClick={handleViewMore}
                            className="bg-slate-500 text-white w-32  px-3 py-1 "
                        >
                            Voir plus
                        </button>
                    )}
                    {displayCount > 4 && (
                        <button
                            onClick={handleViewLess}
                            className="bg-slate-500 text-white w-32 px-3 py-1"
                        >
                            Voir moins
                        </button>
                    )}

                </div>

                {/* Articles populaires */}
                <div className="font-bold text-2xl">Articles populaires</div>
                <div className="grid grid-cols-1 sm:grid-cols-2  sm:m-3 lg:grid-cols-3 gap-2">
                    {notes.map((item) => {
                        const desc = `${item.description
                            .split("")
                            .slice(0, 20)
                            .join("")} ...`;
                        const titre = `${item.titre.split("").slice(0, 80).join("")}`;
                        return (
                            <div
                                key={item.id}
                                className={`border border-slate-300 ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} shadow-sm w-full sm:h-96 rounded-tl-lg rounded-tr-lg space-x-6`}
                            >
                                {item.images && (
                                    <img
                                        src={item.images}
                                        alt=""
                                        className="w-full h-48 object-cover object-top rounded-tl-lg rounded-tr-lg"
                                    />
                                )}
                                <div className="flex items-center space-x-36">
                                    <div className="flex items-center">
                                        <FaRegUser />
                                        {item.username}
                                    </div>
                                </div>
                                <div className={`font-bold ${isDark === "dark" ? 'text-white' : 'text-black'}`}>
                                    <Link onClick={() => HundlwScrollTop()} to={`/voyage/detail/${item.id}`}>{titre}</Link>
                                </div>
                                <p className={`w-72 break-words ${isDark === "dark" ? 'text-white' : 'text-black'}`}>{desc}</p>
                                <p className={`${isDark === "dark" ? 'text-white' : 'text-black'}`}>{item.date}</p>
                                <div className="flex items-center space-x-8 mt-4">
                                    <div className="flex items-center">
                                        <Link onClick={() => HundlwScrollTop()} to={`/voyage/detail/${item.id}`}>Learn More</Link>{" "}
                                        <FaArrowRight />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default VoyageVisitorDetail;
