import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";

const PublicationAdmin = () => {
    const [isloading, setisLoading] = useState(true);
    const [error, seterror] = useState(null);
    const { isDark } = useThemes()
    const [posts, setposts] = useState([]);

    const [mobile, SetMobile] = useState(window.innerWidth < 844)


    console.log("window.innerWidth < 841", window.innerWidth < 844)

    useEffect(() => {
        const HundeSize = () => {
            SetMobile(window.innerWidth < 844)
        }

        window.addEventListener('resize', HundeSize)

        return () => window.removeEventListener('resize', HundeSize)
    }, [])



    useEffect(() => {
        const recuperer = async () => {
            try {
                const response = await axios.get(
                    "https://ttcvbackend.eac-advisor.org/posts/posts"
                );
                const filterItem = response.data.filter(
                    (item) => item.is_deleted === false && item.is_valid === true
                );
                setposts(filterItem);
            } catch (error) {
                console.error("Erreur lors de la recuperation des publications", error);
                seterror("Erreur lors de la recuperation des publications");
            } finally {
                setisLoading(false);
            }
        };
        recuperer();
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const itemsPerPage = 20; // Nombre d'articles par page

    // Filtrer les données en fonction du terme de recherche
    const filteredData = posts.filter((item) =>
        item.titre.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Calculer les indices pour les éléments à afficher
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Changer de page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Nombre total de pages
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    return (
        <section className={`w-full ${isDark === "dark" ? null : 'bg-gray-100'} p-2 relative md:max-auto`}>
            <div>
                <div className="font-bold m-2 text-xl max-sm:text-center md:text-2xl">
                    La gestion des articles est ici cliquer sur le titre pour voir le
                    detail :
                </div>

                
                <input
                    type="text"
                    placeholder="Rechercher par titre..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(1); // Réinitialiser à la première page lors d'une recherche
                    }}
                    className="mb-4 p-2 border bg-transparent rounded"
                />

                <div className="overflow-y-hidden overflow-x-auto">
                    <table className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} border border-black w-full h-full`}>
                        <thead>
                            <tr className="bg-blue-500 text-white uppercase">
                                <th className="p-3 border-r text-left">ID</th>
                                <th className="p-3 border-r text-left">Titre</th>
                                <th className="p-3 border-r text-left ">
                                    Username
                                </th>
                                <th className="p-3 border-r text-left">
                                    Vues
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((post) => (
                                    <tr
                                        key={post.id}
                                        className="border-b border-gray-400 hover:text-black transition-all hover:bg-gray-200"
                                    >
                                        <td className="p-3   text-nowrap text-left border-r border-gray-400">
                                            {post.id}
                                        </td>
                                        <td className="p-3  text-nowrap text-left border-r border-gray-400 text-blue-500 underline">
                                            <Link to={`${post.id}`}>{post.titre}</Link>
                                        </td>
                                        <td className="p-3 text-nowrap text-left border-r border-gray-400">
                                            {post.username}
                                        </td>
                                        <td className="p-3 text-nowrap text-left border-r border-gray-400">
                                            {post.views_count}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="py-3 text-center">
                                        Aucun publication trouvé
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => paginate(index + 1)}
                            className={`mx-1 px-4 py-2 border rounded ${currentPage === index + 1
                                ? "bg-blue-500 text-white"
                                : "bg-white text-blue-500"
                                }`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PublicationAdmin;
