import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";
import TranslateComponent from "../../TranslateComponent/TranslateComponent";
import { Drawer, Popover, Whisper } from "rsuite";
import { IoMdHome } from "react-icons/io";
import { IoMdAddCircle } from "react-icons/io";
import { MdAddShoppingCart } from "react-icons/md";
import { IoChatbubbles } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { GiNewspaper } from "react-icons/gi";

const NavBarsAdmin = () => {
    const [username, setUsername] = useState("");
    const { isLogin, SetIsLogin, toggleDarkMode, isDark } = useThemes()

    const navigate = useNavigate();

    useEffect(() => {
        const checkLoggedInUser = async () => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                try {
                    const config = {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    };
                    const response = await axios.get(
                        "https://ttcvbackend.eac-advisor.org/accounts/user/",
                        config
                    );
                    SetIsLogin(true);
                    setUsername(response.data.username);
                } catch (error) {
                    console.error("Error fetching user data:", error);
                    SetIsLogin(false);
                    setUsername("");
                }
            } else {
                SetIsLogin(false);
                setUsername("");
            }
        };
        checkLoggedInUser();
    }, []);

    const handleLogout = async () => {
        try {
            const refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken) {
                await axios.post(
                    "https://ttcvbackend.eac-advisor.org/accounts/token/refresh/",
                    { refresh: refreshToken }
                );
                console.log("Logout successful!");
            }
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            SetIsLogin(false);
            setUsername("");
            navigate("/loginA");
        } catch (error) {
            console.error("Failed to logout:", error);
        }
    };


    const [mobile2, setMobile2] = useState(window.innerWidth < 500)

    const [mobile1, setMobile1] = useState(window.innerWidth < 444)
    const [mobile3, setMobile3] = useState(window.innerWidth < 322)
    const [isOpened, setisOpened] = useState(false)

    useEffect(() => {
        const handleSize = () => {
            setMobile2(window.innerWidth < 500);

            setMobile1(window.innerWidth < 444);
            setMobile3(window.innerWidth < 322);
            setisOpened(false)
        };

        window.addEventListener('resize', handleSize);
        return () => {
            window.removeEventListener('resize', handleSize);
        };
    }, []);



    const menus = [
        { id: 1, text: 'Accueil', url: 'dashboard', icon: <IoMdHome size={24} /> },
        { id: 2, text: 'Publication', url: 'publier', icon: <IoMdAddCircle size={24} /> },
        { id: 3, text: 'Publicité', url: 'publiciter', icon: <MdAddShoppingCart size={24} /> },
        { id: 4, text: 'Messages', url: 'message', icon: <IoChatbubbles size={24} /> },
        { id: 5, text: 'Gestion des utilisateurs', url: 'users', icon: <FaUsers size={24} /> },
        { id: 6, text: 'Gestion des publications', url: 'posts', icon: <GiNewspaper size={24} /> }
    ];

    const [selectedMenuId, setSelectedMenuId] = useState(menus[0].id);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { mobile } = useThemes();
    const location = useLocation();

    useEffect(() => {
        const matchedMenu = menus.find(menu => {
            const regex = new RegExp(`^/admin/${menu.url}`);
            return regex.test(location.pathname);
        });

        if (matchedMenu) {
            setSelectedMenuId(matchedMenu.id);
        }
    }, [location.pathname, menus]);

    const handleMenuClick = (id, url) => {
        setSelectedMenuId(id);
        localStorage.setItem('selectedMenuId', id);

        const newUrl = `/admin/${url}`;
        window.location.href = newUrl;
    };


    return (
        <div className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'}  w-full border-b border-slate-300 px-3 h-16   flex items-center justify-between`}>
            <div className="flex items-center gap-1">
                {mobile2 && <button
                    className="bg-blue-700 text-white first-letter:uppercase p-2  cursor-pointer rounded-md"
                    onClick={() => setisOpened(true)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                    </svg>
                </button>
                }
                <div className="text-xl">
                    <span className=" hidden sm:block">Bienvenu(e)</span> <span className="font-bold first-letter:uppercase">{username}</span>
                </div>
            </div>

            <div className="flex items-center">
                <TranslateComponent />


                <div onClick={toggleDarkMode} className={`relative p-1 ${mobile3 ? 'ml-0' : 'ml-2'} h-max`}>
                    {isDark === "dark" ? (
                        <div className="p-2 bg-green-100 hover:bg-green-300 cursor-pointer text-[#666feb] rounded-lg">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-brightness-high-fill animate-spin"
                                viewBox="0 0 16 16"
                            >
                                <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
                            </svg>
                        </div>
                    ) : (
                        <div className="p-2 bg-green-100 hover:bg-green-300 cursor-pointer text-[#666feb] rounded-lg">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-moon-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" />
                            </svg>
                        </div>
                    )}
                </div>


                {mobile2 ?
                    <>

                        <Whisper
                            trigger='click'
                            placement="bottomEnd"
                            speaker={
                                <Popover>
                                    <div onClick={() => navigate("/admin/adminCompte")} className="flex cursor-pointer justify-start rounded-md items-center text-black bg-gray-200 p-2 uppercase font-serif font-extrabold ">{username && username}</div>

                                    <div onClick={handleLogout} className="flex justify-center mt-1 rounded-md cursor-pointer items-center text-black bg-gray-200 p-2 uppercase ">Déconnexion</div>
                                </Popover>
                            }
                        >
                            <div className="w-8 rounded cursor-pointer h-8 border flex justify-center items-center uppercase text-2xl font-serif font-extrabold text-blue-600">{username && username.split('')[0]}</div>
                        </Whisper>
                    </>
                    :
                    <Whisper
                        trigger='click'
                        placement="bottomEnd"
                        speaker={
                            <Popover>
                                <div onClick={() => navigate("/admin/adminCompte")} className="flex cursor-pointer justify-start rounded-md items-center text-black bg-gray-200 p-2 uppercase font-serif font-extrabold ">{username && username}</div>

                                <div onClick={handleLogout} className="flex justify-center mt-1 rounded-md cursor-pointer items-center text-black bg-gray-200 p-2 uppercase ">Déconnexion</div>
                            </Popover>
                        }
                    >
                        <button
                            className="bg-red-700 text-white first-letter:uppercase px-3 py-2 cursor-pointer rounded-md"
                                       >
                            {username && username}
                        </button>
                    </Whisper>
                }

            </div>

            <Drawer open={isOpened} placement="left" onClose={() => setisOpened(false)} size={mobile2 ? 'full' : 'xs'}>
                <Drawer.Header>
                    <Drawer.Title>Option de navigation</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <div className="p-2 w-full">
                        {menus.map((item) => (
                            <div key={item.id}>
                                <Link
                                    to={item.url}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleMenuClick(item.id, item.url);
                                    }}
                                    className={`flex items-center gap-2 py-3 ${selectedMenuId === item.id ? 'text-blue-500 bg-gray-200 rounded-lg' : ''}`}
                                >
                                    {item.icon}
                                    <span>{item.text}</span>
                                </Link>
                            </div>
                        ))}
                    </div>
                </Drawer.Body>
            </Drawer>
        </div>
    );
};

export default NavBarsAdmin;
