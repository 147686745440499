import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useThemes } from "../../../UserContext/UserContext";

const PubliciteListModifier = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { isDark } = useThemes()
  const [posts, setPosts] = useState({
    titre: "",
    content: "",
    user: "",
    created: "",
    images: [],
    is_deleted: false,
    place: "",
    link: "",
  });

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [formErrors, setFormErrors] = useState({});

  const getToken = () => {
    return localStorage.getItem("accessToken");
  };

  const axiosInstance = axios.create({
    baseURL: "https://ttcvbackend.eac-advisor.org",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

  const handle = (e) => {
    const { name, value, type, files, checked } = e.target;
    setPosts({
      ...posts,
      [name]: type === "file" ? files[0] : type === "checkbox" ? checked : value,
    });
    setError(null);
    setFormErrors({ ...formErrors, [name]: null });
  };

  const validateForm = () => {
    const errors = {};
    if (!posts.titre) errors.titre = "Le titre est requis.";
    if (!posts.content) errors.content = "La description est requise.";
    return errors;
  };

  useEffect(() => {
    const recuperer = async () => {
      try {
        const response = await axiosInstance.get(
          `posts/adds/${id}/`
        );

        const data = Array.isArray(response.data) ? response.data : [response.data];

        const filterItem = data.filter(
          (item) => item.is_deleted === false
        );

        if (filterItem.length > 0) {
          const post = filterItem[0];
          post.created = formatDate(post.created);
          setPosts(post);
        }
      } catch (error) {
        console.error("Erreur de récupération des informations", error);
        setError("Erreur lors de la récupération");
      } finally {
        setIsLoading(false);
      }
    };
    recuperer();
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    Object.keys(posts).forEach((key) => {
      formData.append(key, posts[key]);
    });

    try {
      await axiosInstance.put(`/posts/adds/${id}/`, formData);
      alert("Publicité mise à jour avec succès !");
      navigate("/admin/publiciter");
    } catch (error) {
      console.error("Erreur de publication", error);
      if (error.response) {
        const errorMessage =
          error.response.data.detail || JSON.stringify(error.response.data);
        setError(`Erreur: ${errorMessage}`);
      } else {
        setError("Erreur dans la configuration de la requête.");
      }
    }
  };

  const handleDelete = async () => {
    try {
      await axiosInstance.patch(`/posts/adds/${id}/`, { is_deleted: true });
      alert("Publicité supprimée avec succès !");
      navigate("/admin/dashboard");
    } catch (error) {
      console.error("Erreur de suppression", error);
      if (error.response) {
        const errorMessage =
          error.response.data.detail || JSON.stringify(error.response.data);
        setError(`Erreur: ${errorMessage}`);
      } else {
        setError("Erreur dans la configuration de la requête.");
      }
    }
  };

  return (
    <section className={`w-full ${isDark === "dark" ? null : 'bg-gray-100'} p-2 relative md:max-auto`}>
      <div className="flex justify-end rounded-md items-center w-full">
        <div onClick={() => navigate('/admin/publiciter')} className="bg-blue-700 text-white cursor-pointer p-1 w-max">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
          </svg>
        </div>
      </div>
      <div className="font-bold text-2xl">Détail & Modification</div>
      {isLoading ? (
        <p>Chargement...</p>
      ) : (
        <div className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} w-full h-full p-5`}>
          <div>
            <span className="font-bold text-xl">ID:</span> {posts.id}
          </div>
          <form className="p-4" onSubmit={handleUpdate}>
            <div className="flex flex-col mb-5 ">
              <label htmlFor="titre" className="block text-lg">Titre :</label>
              <input
                type="text"
                className="border focus:border-blue-600 rounded outline-none bg-transparent p-3 w-full"
                value={posts.titre}
                onChange={handle}
                name="titre"
                required
              />
              {formErrors.titre && (
                <span className="text-red-500">{formErrors.titre}</span>
              )}
            </div>
            <div className="flex flex-col mb-5 ">
              <label htmlFor="content" className="block text-lg">Description :</label>
              <textarea
                className="border focus:border-blue-600 min-h-[10em] max-h-[25em] rounded outline-none bg-transparent p-3 w-full"
                value={posts.content}
                onChange={handle}
                name="content"
                required
              ></textarea>
              {formErrors.content && (
                <span className="text-red-500">{formErrors.content}</span>
              )}
            </div>
            <div className="flex flex-col mb-5 ">
              <label htmlFor="created" className="block text-lg">Created :</label>
              <input
                type="text"
                className="border focus:border-blue-600 rounded outline-none bg-transparent p-3 w-full"
                value={posts.created}
                onChange={handle}
                name="created"
                readOnly
              />
            </div>
            <div className="flex flex-col mb-5 ">
              <label htmlFor="images" className="block text-lg">Images :</label>
              <input
                type="file"
                className="border focus:border-blue-600 rounded outline-none bg-transparent p-3 w-full"
                onChange={handle}
                name="images"
              />
            </div>
            <p>Images</p>
            {Array.isArray(posts.images) && posts.images.map((image, index) => (
              <img key={index} src={image} className="w-32 h-32" alt={`Image ${index}`} />
            ))}
            <div className="flex  justify-start items-center mb-5 ">
              <label htmlFor="is_deleted" className="block text-lg">Statut (cocher pour supprimer) :</label>
              <input
                type="checkbox"
                checked={posts.is_deleted}
                onChange={handle}
                className="cursor-pointer"
                name="is_deleted"
              />
            </div>
            <div className="flex flex-col mb-5 ">
              <label htmlFor="place" className="block text-lg">Où est placée votre publicité :</label>
              <input
                type="text"
                className="border focus:border-blue-600 rounded outline-none bg-transparent p-3 w-full"
                value={posts.place}
                onChange={handle}
                name="place"
              />
            </div>
            <div className="flex flex-col mb-5 ">
              <label htmlFor="link" className="block text-lg">Lien :</label>
              <input
                type="text"
                className="border focus:border-blue-600 rounded outline-none bg-transparent p-3 w-full"
                value={posts.link}
                onChange={handle}
                name="link"
              />
            </div>
            <div className="flex items-center space-x-6">
              <button type="submit" className="px-7 py-2 bg-blue-500 text-white">
                Modifier
              </button>
              <button
                type="button"
                onClick={handleDelete}
                className="px-7 py-2 bg-red-500 text-white"
              >
                Supprimer
              </button>
            </div>
          </form>
        </div>
      )}
    </section>
  );
};

export default PubliciteListModifier;
