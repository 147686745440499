import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useThemes } from "../../../UserContext/UserContext";
import { toast } from "react-toastify";

const PublicationAdminDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { isDark } = useThemes()
    const [posts, setPosts] = useState({
        titre: "",
        description: "",
        username: "",
        created: "",
        images: null,
        views_count: 0,
        likes: [],
        lieu: "",
        is_valid: false,
        is_deleted: false,
    });

    const [currentImage, setCurrentImage] = useState(null);
    const [imagePreview, setImagePreview] = useState("");
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [isBouton,SetIsBouton]=useState(false)

    const getToken = () => localStorage.getItem("accessToken");

    const axiosInstance = axios.create({
        baseURL: "https://ttcvbackend.eac-advisor.org",
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    });

    const handleInputChange = (e) => {
        const { name, type, files, checked } = e.target;

        if (type === "file" && files.length > 0) {
            setPosts((prevState) => ({
                ...prevState,
                [name]: files[0],
            }));
            setImagePreview(URL.createObjectURL(files[0]));
        } else {
            setPosts((prevState) => ({
                ...prevState,
                [name]: type === "checkbox" ? checked : e.target.value,
            }));
        }

        setError(null);
        setFormErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    };

    const validateForm = () => {
        const errors = {};
        if (!posts.titre) errors.titre = "Le titre est requis.";
        if (!posts.description) errors.description = "La description est requise.";
        return errors;
    };

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axiosInstance.get(`/posts/posts/${id}`);
                const postData = response.data;

                const formattedDate = new Date(postData.created);
                const dateString = `${formattedDate.getFullYear()}/${String(
                    formattedDate.getMonth() + 1
                ).padStart(2, "0")}/${String(formattedDate.getDate()).padStart(2, "0")}`;
                postData.created = dateString;

                setPosts(postData);
                setCurrentImage(postData.images);
                setImagePreview(postData.images);
            } catch (error) {
                console.error("Erreur de récupération des informations", error);
                setError("Erreur lors de la récupération");
            } finally {
                setIsLoading(false);
            }
        };
        fetchPost();
    }, [id]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setFormErrors(validationErrors);
            return;
        }

        const formData = new FormData();
        const { likes, ...postsToSend } = posts;

        Object.keys(postsToSend).forEach((key) => {
            formData.append(key, postsToSend[key] || "");
        });

        if (posts.images) {
            formData.append("images", posts.images);
        }

        try {
            SetIsBouton(true)
            await axiosInstance.patch(`/posts/posts/${id}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            alert("Publication mise à jour avec succès !");
            navigate("/admin/dashboard");
            SetIsBouton(false)

        } catch (error) {
            console.error("Erreur de publication", error);
            const errorMessage = error.response?.data.detail || "Erreur inconnue.";
            setError(`Erreur: ${errorMessage}`);
            SetIsBouton(false)
        }
    };

    const handleDelete = async () => {
        const confirmDelete = window.confirm(
            "Êtes-vous sûr de vouloir supprimer cette publication ?"
        );
        if (!confirmDelete) return;

        try {
            await axiosInstance.patch(`/posts/posts/${id}/`, { is_deleted: true });
            toast.success("Publication supprimée avec succès !");
            navigate("admin/posts");
        } catch (error) {
            console.error("Erreur de suppression", error);
            const errorMessage = error.response?.data.detail || "Erreur inconnue.";
            setError(`Erreur de suppression: ${errorMessage}`);
        }
    };

    return (
        <section className={`w-full ${isDark === "dark" ? null : 'bg-gray-100'} p-2 relative md:max-auto`}>
            <div className="flex justify-end rounded-md items-center w-full">
                <div onClick={() => navigate('/admin/posts')} className="bg-blue-700 text-white cursor-pointer p-1 w-max">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg>
                </div>
            </div>
            <h1 className="text-2xl font-bold mb-6">Détails & Modification</h1>
            <div className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-md space-y-8`}>
                <h2 className="text-lg font-semibold">
                    <span className="font-bold">ID:</span> {posts.id}
                </h2>
                <form onSubmit={handleUpdate} encType="multipart/form-data">
                    <div className="space-y-4">
                        <div className="flex flex-col mb-5 ">
                            <label htmlFor="titre" className="block text-lg">Titre :</label>
                            <input
                                type="text"
                                className="border focus:border-blue-600 rounded outline-none bg-transparent p-3 w-full"
                                value={posts.titre}
                                onChange={handleInputChange}
                                name="titre"
                            />
                            {formErrors.titre && <span className="text-red-500">{formErrors.titre}</span>}
                        </div>

                        <div className="flex flex-col mb-5 ">
                            <label htmlFor="description" className="block text-lg">Description :</label>
                            <textarea
                                className="border focus:border-blue-600 min-h-[10em] max-h-[25em] rounded outline-none bg-transparent p-3 w-full"
                                value={posts.description}
                                onChange={handleInputChange}
                                name="description"
                            ></textarea>
                            {formErrors.description && <span className="text-red-500">{formErrors.description}</span>}
                        </div>

                        <div className="flex flex-col mb-5 ">
                            <label htmlFor="created" className="block text-lg">Créé :</label>
                            <input
                                type="text"
                                className="border focus:border-blue-600 rounded outline-none bg-transparent p-3 w-full"
                                value={posts.created}
                                readOnly
                            />
                        </div>

                        <div className="flex flex-col mb-5 ">
                            <label htmlFor="images" className="border rounded-md block cursor-pointer w-full bg-transparent py-3 px-4 bg-gray-200">Ajouter une image :</label>
                            <input
                                type="file"
                                name="images"
                                id="images"
                                hidden
                                onChange={handleInputChange}
                                className="w-full"
                            />
                        </div>

                        {imagePreview && (
                            <div className="w-40 h-40 border rounded-md overflow-hidden mt-2">
                                <img src={imagePreview} alt="Aperçu" className="w-full h-full object-cover" />
                            </div>
                        )}

                        <div className="flex flex-col mb-5 ">
                            <p className="px-5 py-2 bg-blue-100 rounded-md text-blue-800 inline-block font-semibold">
                                Vue : {posts.views_count}
                            </p>
                        </div>

                        <div className="flex flex-col mb-5 ">
                            <label htmlFor="lieu" className="block text-lg">Lieu :</label>
                            <input
                                type="text"
                               className="border focus:border-blue-600 rounded outline-none bg-transparent p-3 w-full"
                                value={posts.lieu}
                                onChange={handleInputChange}
                                name="lieu"
                            />
                        </div>

                        <div className="flex items-center space-x-2">
                            <label htmlFor="is_valid" className="font-semibold">Statut (case cochée si valide) :</label>
                            <input
                                type="checkbox"
                                checked={posts.is_valid}
                                onChange={handleInputChange}
                                name="is_valid"
                                className="h-5 w-5"
                            />
                        </div>

                        <div className="flex items-center space-x-2">
                            <label htmlFor="is_deleted" className="font-semibold">Statut (cocher s'il est supprimé) :</label>
                            <input
                                type="checkbox"
                                checked={posts.is_deleted}
                                onChange={handleInputChange}
                                name="is_deleted"
                                className="h-5 w-5"
                            />
                        </div>
                    </div>

                    <div className="flex items-center space-x-4 mt-6">
                        <button disabled={isBouton} type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md">Modifier</button>
                        <button
                            type="button"
                            className="px-4 py-2 bg-red-600 text-white rounded-md"
                            onClick={handleDelete}
                        >
                            Supprimer
                        </button>
                    </div>
                </form>
            </div>
            {error && <div className="text-red-500 mt-4">{error}</div>}
        </section>
    );
};

export default PublicationAdminDetail;
