import { CheckCircle, Globe, Users, BookOpen, PenTool } from "lucide-react"; // Importez les icônes nécessaires
import { useThemes } from "../../../UserContext/UserContext";


const Propos = () => {
    const { isDark, mobileTop } = useThemes()
    return (
        <section className={`w-full ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} p-2 ${mobileTop ? 'mt-[6vh]' : 'mt-[12vh]'}  relative  md:max-auto `}>
            <div className="max-w-screen-lg mx-auto">
                {/* About Section */}
                <div className="mb-12">
                    <h1 className="font-bold text-2xl text-blue-600 md:text-3xl text-center  mb-6">
                        Qu'est-ce que TTCV ?
                    </h1>
                    <p className={`leading-relaxed sm:text-xl text-justify ${isDark === "dark" ? 'text-gray-100' : 'text-gray-900'}`}>
                        TTCV est une plateforme dédiée au tourisme, au voyage, et à la
                        culture. Nous croyons que chaque personne est un voyageur, et TTCV
                        est là pour enrichir chaque expérience de voyageur. Grâce à TTCV,
                        planifiez des voyages sur mesure, et découvrez une visibilité
                        mondiale de vos destinations et de vos partenaires.
                    </p>
                </div>

                {/* Our Story */}
                <div className="mb-12 flex items-start">
                    <Globe className="text-blue-800 mr-4 mt-1" size={24} />
                    <div>
                        <h1 className="font-bold text-2xl md:text-3xl text-blue-800 mb-4">
                            Notre Histoire
                        </h1>
                        <p className={`${isDark === "dark" ? 'text-gray-100' : 'text-gray-900'} sm:text-xl   leading-relaxed  text-justify `}>
                            Fondée sur la passion pour la découverte et l'exploration, TTCV
                            est née de l'envie de partager des expériences culturelles uniques
                            et de créer un impact positif sur les industries du tourisme et de
                            la culture. Nous grandissons chaque jour avec notre communauté de
                            voyageurs et de partenaires.
                        </p>
                    </div>
                </div>

                {/* Mission Section */}
                <div className="mb-12 flex items-start">
                    <Users className="text-blue-800 mr-4 mt-1" size={24} />
                    <div>
                        <h1 className="font-bold text-2xl md:text-3xl text-blue-800 mb-4">
                            Notre Mission
                        </h1>
                        <p className={`${isDark === "dark" ? 'text-gray-100' : 'text-gray-900'} sm:text-xl   leading-relaxed  text-justify `}>
                            Nous aspirons à promouvoir les produits touristiques et culturels
                            qui enrichissent le patrimoine mondial et soutiennent le
                            développement local. TTCV est une plateforme qui inspire à
                            apprendre, voyager, et créer des emplois dans un cadre
                            collaboratif.
                        </p>
                    </div>
                </div>

                {/* Our Values */}
                <div className="mb-12 flex items-start">
                    <CheckCircle className="text-blue-800 mr-4 mt-1" size={24} />
                    <div>
                        <h1 className="font-bold text-2xl md:text-3xl text-blue-800 mb-4">
                            Nos Valeurs
                        </h1>
                        <p className={`${isDark === "dark" ? 'text-gray-100' : 'text-gray-900'} sm:text-xl  leading-relaxed  text-justify `}>
                            Chez TTCV, nous valorisons la découverte, le respect de la
                            diversité culturelle et l'engagement envers le développement
                            durable. Nous sommes engagés à offrir un espace inclusif et
                            collaboratif pour l'épanouissement de toutes les cultures.
                        </p>
                    </div>
                </div>

                {/* Vision Section */}
                <div className="mb-12 flex items-start">
                    <BookOpen className="text-blue-800 mr-4 mt-1" size={24} />
                    <div>
                        <h1 className="font-bold text-2xl md:text-3xl text-blue-800 mb-4">
                            Notre Vision
                        </h1>
                        <p className={`${isDark === "dark" ? 'text-gray-100' : 'text-gray-900'} sm:text-xl   leading-relaxed  text-justify `}>
                            Notre vision est de devenir la première référence mondiale en
                            matière de tourisme et de culture, en offrant aux voyageurs et aux
                            partenaires une plateforme qui célèbre la beauté et la diversité
                            du monde.
                        </p>
                    </div>
                </div>

                {/* Publication Guide */}
                <div className="mb-12 flex items-start">
                    <PenTool className="text-blue-800 mr-4 mt-1" size={24} />
                    <div>
                        <h1 className="font-bold text-2xl md:text-3xl text-blue-800 mb-4">
                            Comment Publier sur TTCV ?
                        </h1>
                        <div className={`space-y-4 sm:text-xl ${isDark === "dark" ? 'text-gray-100' : 'text-gray-900'}`}>
                            <p>Pour publier un article :</p>
                            <ol className="list-decimal ml-8 space-y-2">
                                <li>Ajoutez un titre principal.</li>
                                <li>Indiquez le lieu (par exemple : Paris, France).</li>
                                <li>Ajoutez l'image principale.</li>
                                <li>
                                    Rédigez votre description, avec sous-titres, images, et
                                    paragraphes.
                                </li>
                                <li>
                                    Formatage :
                                    <ul className="list-disc ml-6 mt-2 space-y-2">
                                        <li>
                                            <strong>Sous-titre :</strong> ##Votre sous-titre
                                        </li>
                                        <li>
                                            <strong>Paragraphe :</strong> Texte de votre paragraphe...
                                        </li>
                                        <li>
                                            <strong>Image :</strong> ![Titre de l'image](URL de
                                            l'image)
                                        </li>
                                    </ul>
                                </li>
                                <li>Sélectionnez la catégorie et publiez votre article.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Propos;
