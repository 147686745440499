import React, { useEffect, useState } from "react";
import { useThemes } from "../../../UserContext/UserContext";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaRegUser, FaArrowRight } from "react-icons/fa";
import { MdBusinessCenter, MdOutlineDateRange } from "react-icons/md";
import { formatDistanceToNow } from "date-fns";
import SpinnserData from "../Pages/SpinnerData";
function CultureHomeVisiteur() {
  const { isDark } = useThemes();
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSpinnerData, SetIsSpinnerData] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          "https://ttcvbackend.eac-advisor.org/posts/posts/"
        );
        SetIsSpinnerData(false);
        const filteredPosts = response.data.filter(
          (item) =>
            item.is_valid && !item.is_deleted && item.category === "CULTURE"
        );
        setPosts(filteredPosts.slice(0, 4));
      } catch (error) {
        console.error("Erreur lors de la récupération des données", error);
        setError("Erreur de chargement");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (!email) {
      setMessage("Veuillez entrer une adresse e-mail valide.");
      return;
    }

    try {
      await axios.post("https://ttcvbackend.eac-advisor.org/posts/subscribe/", {
        email,
      });
      setMessage("Abonnement réussi !");
      setEmail(""); // Réinitialise le champ email
    } catch (error) {
      setMessage(
        "Erreur lors de l'abonnement. Cet e-mail est peut-être déjà abonné."
      );
    }
  };

  return (
    <section
      className={`w-full mt-10 p-2 ${
        isDark === "dark" ? "bg-gray-800" : "bg-white"
      } p-2  relative  md:max-auto `}
    >
      {isSpinnerData && <SpinnserData />}
      <hr />

      <div>
        <div className="absolute flex  w-full justify-center ">
          <div className="text-center  rounded-xl bg-gray-600 sm:text-[2em]  font-extrabold  text-white sm:px-7 px-5  py-2">
            Culture
          </div>
        </div>
        {/* Première partie */}
        <div className="grid grid-cols-1 sm:grid-cols-2 p-1 sm:m-3 lg:grid-cols-3 gap-2">
          {posts.map((item) => {
            const desc = `${item.description
              .split("")
              .slice(0, 70)
              .join("")}...`;
            const titre = `${item.titre.split("").slice(0, 150).join("")}`;
            return (
              <div
                data-aos="zoom-in-down"
                data-aos-duration="1000"
                key={item.id}
                className="w-full p-2"
              >
                <div className="w-full sm:h-54 overflow-hidden rounded-lg">
                  {item.images && (
                    <img
                      src={item.images}
                      alt=""
                      className="w-full h-full object-cover object-top rounded-lg md:w-full md:h-full"
                    />
                  )}
                </div>
                <div className="w-full p-3">
                  <div className="font-bold w-72 break-words md:text-2xl">
                    <Link
                      className="hover:no-underline focus:no-underline"
                      to={`/culture/detail/${item.id}`}
                    >
                      {titre}
                    </Link>
                  </div>
                  <p
                    className={` ${
                      isDark === "dark" ? "text-gray-300" : "text-gray-900"
                    } break-words w-72`}
                  >
                    {desc}{" "}
                    <Link
                      to={`/culture/detail/${item.id}`}
                      className="hover:no-underline focus:no-underline"
                    >
                      voir plus
                    </Link>
                  </p>
                  <p
                    className={`italic ${
                      isDark === "dark" ? "text-gray-300" : "text-gray-900"
                    } `}
                  >
                    Tags:
                    <span className="bg-slate-500 px-3 py-0 text-white">
                      {item.category}
                    </span>
                  </p>
                  <div className="flex items-center space-x-4">
                    <p
                      className={`flex items-center ${
                        isDark === "dark" ? "text-gray-300" : "text-gray-900"
                      } `}
                    >
                      <FaRegUser className="m-2" />
                      {item.username}
                    </p>
                    <p
                      className={`flex items-center ${
                        isDark === "dark" ? "text-gray-300" : "text-gray-900"
                      } `}
                    >
                      <MdBusinessCenter className="m-2" />
                      {item.profession}
                    </p>
                  </div>
                  <p
                    className={`flex items-center ${
                      isDark === "dark" ? "text-gray-300" : "text-gray-900"
                    } `}
                  >
                    <MdOutlineDateRange className="m-2" />
                    {formatDistanceToNow(new Date(item.created), {
                      addSuffix: true,
                    })}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-1 flex justify-end w-full">
          <Link
            to="/culture"
            className="flex  text-lg items-center p-1  text-blue-400 underline"
          >
            Voir plus des articles
            <FaArrowRight />
          </Link>
        </div>
        <div className="w-full flex justify-end">
          <div
            data-aos="fade-left"
            data-aos-duration="500"
            className="mt-5 p-4 md:p-0"
          >
            <h1
              className={`font-bold ${
                isDark === "dark" ? "text-gray-300" : "text-gray-600"
              }  text-xl`}
            >
              S'abonner !
            </h1>
            <p
              className={`${
                isDark === "dark" ? "text-gray-300" : "text-gray-600"
              } `}
            >
              S'abonner pour recevoir des nouvelles articles dans votre adresse
              e-mail
            </p>
            <form onSubmit={handleSubscribe} className="mt-3">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Entrer votre adresse e-mail..."
                className={`md:w-80 w-60  bg-transparent rounded-md ${
                  isDark === "dark" ? "text-gray-300" : "text-gray-600"
                } focus:outline-none focus:border-2 focus:border-[#479bd9] px-5 py-2 border border-slate-400`}
                required
              />
              <br />
              <button
                type="submit"
                className="md:w-80 mt-3 w-40 px-5 py-2 cursor-pointer bg-blue-500 text-white"
              >
                S'abonner
              </button>
            </form>
            {message && <p className="text-green-500">{message}</p>}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CultureHomeVisiteur;
