import React, { useState } from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function LoginAdmin() {
    const { isDark, mobileTop } = useThemes()

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        setError(null);
    };

    const validateForm = () => {
        const { email, password } = formData;
        let errors = {};
        const emailRegex = /\S+@\S+\.\S+/;

        if (!email) {
            errors.email = "L'adresse e-mail est requise.";
        } else if (!emailRegex.test(email)) {
            errors.email = "Adresse e-mail invalide.";
        }

        if (!password) {
            errors.password = "Le mot de passe est requis.";
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) {
            return;
        }

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setError(validationErrors);
            return;
        }

        setIsLoading(true);

        try {
            const response = await axios.post(
                "https://ttcvbackend.eac-advisor.org/accounts/loginA/",
                formData
            );
            setSuccessMessage("Connexion réussie !");
            localStorage.setItem("accessToken", response.data.tokens.access);
            localStorage.setItem("refreshToken", response.data.tokens.refresh);
            navigate("/admin/dashboard");
        } catch (error) {
            console.log("erreur loginA", error)
            setError("Email ou mot de passe invalide.");
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className={`w-full  ${isDark === "dark" ? 'bg-slate-600 ' : 'bg-gray-100'} ${mobileTop ? 'mt-[7vh]' : 'mt-[13vh]'}  p-2  relative  md:max-auto `}>
            <form
                className={`lex flex-col sm:p-4 p-2 shadow-lg gap-3 rounded-lg  ${isDark === "dark" ? 'bg-gray-800 text-white' : 'bg-white'} border border-slate-300 w-full`}
                onSubmit={handleSubmit}
            >
                <div className="font-bold sm:text-2xl  text-center">Connexion | Admin</div>
                {error && (
                    <p className="text-red-500">
                        {typeof error === "string"
                            ? error
                            : Object.values(error).map((err, index) => (
                                <span key={index}>
                                    {err}
                                    <br />
                                </span>
                            ))}
                    </p>
                )}
                {successMessage && <p className="text-green-500">{successMessage}</p>}
                <input
                    type="text"
                    placeholder="Adresse e-mail..."
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="px-2 py-3  bg-transparent rounded-md w-full outline-none border border-slate-600 focus:border-2 focus:border-blue-500"
                />
                <div className="relative my-5">
                    <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Votre mot de passe"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className="px-2 py-3  bg-transparent rounded-md w-full outline-none border border-slate-600 focus:border-2 focus:border-blue-500"
                    />
                    <button
                        type="button"
                        className="absolute right-2 top-2 text-gray-500"
                        onClick={() => setShowPassword((prev) => !prev)}
                        aria-label={
                            showPassword
                                ? "Masquer le mot de passe"
                                : "Afficher le mot de passe"
                        }
                    >
                        {showPassword ? "Masquer" : "Afficher"}
                    </button>
                </div>
                <input
                    type="submit"
                    value={isLoading ? "Chargement..." : "Se connecter"}
                    className="md:px-28 px-20 text-white py-3 bg-blue-500"
                    disabled={isLoading}
                />
            </form>
        </div>
    )
}

export default LoginAdmin