import React, { useState } from 'react';
import { useThemes } from '../../../UserContext/UserContext';

const AdminCompte = () => {
    const { isDark, mobileTop, username, setUsername } = useThemes()
    const [showEditProfileModal, setShowEditProfileModal] = useState(false);
    const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);


    const handleEditProfile = () => {
        setShowEditProfileModal(true);
    };

    const handleEditPassword = () => {
        setShowEditPasswordModal(true);
    };

    const handleSaveProfile = (e) => {
        e.preventDefault();
        // Ajouter la logique pour sauvegarder le profil
        setShowEditProfileModal(false);
    };

    const handleSavePassword = (e) => {
        e.preventDefault();
        // Ajouter la logique pour sauvegarder le mot de passe
        setShowEditPasswordModal(false);
    };

    return (
        <div className={`w-full  ${isDark === "dark" ? null : 'bg-gray-100'} ${mobileTop ? 'mt-[6vh]' : 'mt-[12vh]'}  p-2 flex justify-center  relative  md:max-auto `}>

            <div className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md p-8 w-full max-w-md`}>
                <h2 className="text-2xl font-semibold mb-4">Informations du Compte</h2>
                <div className="space-y-2">
                    <p><strong>Prénom:</strong> {username.first_name}</p>
                    <p><strong>Nom:</strong> {username.last_name}</p>
                    <p><strong>Email:</strong> {username.email}</p>
                    <p><strong>Nom d'utilisateur:</strong> {username.username}</p>
                    <p><strong>Pays:</strong> {username.country}</p>
                    <p><strong>Profession:</strong> {username.profession}</p>
                    <p><strong>Sexe:</strong> {username.sexe}</p>
                </div>
                <div className="mt-6 flex justify-between flex-wrap">
                    <button
                        onClick={handleEditProfile}
                        className="bg-blue-500 text-nowrap  text-white m-2 px-4 py-2 rounded hover:bg-blue-600"
                    >
                        Modifier Compte
                    </button>
                    <button
                        onClick={handleEditPassword}
                        className=" text-nowrap bg-green-500 text-white px-4 py-2 m-2 rounded hover:bg-green-600"
                    >
                        Modifier Mot de Passe
                    </button>
                </div>
            </div>

            {/* Modal pour modifier le compte */}
            {showEditProfileModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-md w-full max-w-md`}>
                        <h2 className="text-xl font-semibold mb-4">Modifier le Compte</h2>
                        <form onSubmit={handleSaveProfile}>
                            <div className="mb-4">
                                <label className="block ">Prénom</label>
                                <input
                                    type="text"
                                    defaultValue={username.first_name}
                                    className="w-full px-3 py-2  bg-transparent border rounded"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block ">Nom</label>
                                <input
                                    type="text"
                                    defaultValue={username.last_name}
                                    className="w-full px-3 py-2  bg-transparent border rounded"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block ">Email</label>
                                <input
                                    type="email"
                                    defaultValue={username.email}
                                    className="w-full px-3 py-2  bg-transparent border rounded"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block ">Profession</label>
                                <input
                                    type="text"
                                    defaultValue={username.profession}
                                    className="w-full px-3 py-2  bg-transparent border rounded"
                                />
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setShowEditProfileModal(false)}
                                    className="mr-2 px-4 py-2 bg-red-300  rounded"
                                >
                                    Annuler
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    Enregistrer
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Modal pour modifier le mot de passe */}
            {showEditPasswordModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-md w-full max-w-md`}>
                        <h2 className="text-xl font-semibold mb-4">Modifier le Mot de Passe</h2>
                        <form onSubmit={handleSavePassword}>
                            <div className="mb-4">
                                <label className="block ">Nouveau Mot de Passe</label>
                                <input
                                    type="password"
                                    className="w-full px-3 py-2  bg-transparent border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block ">Confirmer le Mot de Passe</label>
                                <input
                                    type="password"
                                    className="w-full px-3 py-2  bg-transparent border rounded"
                                    required
                                />
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setShowEditPasswordModal(false)}
                                    className="mr-2 px-4 py-2 bg-red-300  rounded"
                                >
                                    Annuler
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                                >
                                    Enregistrer
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminCompte;
