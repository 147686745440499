import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";


const Login = () => {
    const { SetIsLogin, setOpenLogin, setOpenRegi, setopenforget } = useThemes()

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        setError(null); // Réinitialiser l'erreur lors du changement de valeur
    };

    const validateForm = () => {
        const { email, password } = formData;
        const emailRegex = /\S+@\S+\.\S+/;
        let errors = {};

        if (!email) {
            errors.email = "L'adresse e-mail est requise.";
        } else if (!emailRegex.test(email)) {
            errors.email = "Adresse e-mail invalide.";
        }

        if (!password) {
            errors.password = "Le mot de passe est requis.";
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) return;

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setError(validationErrors);
            return;
        }

        setIsLoading(true);

        try {
            const response = await axios.post(
                "https://ttcvbackend.eac-advisor.org/accounts/login/",
                formData
            );
            setSuccessMessage("Connexion réussie !");
            localStorage.setItem("accessToken", response.data.tokens.access);
            localStorage.setItem("refreshToken", response.data.tokens.refresh);
            navigate("/");
            window.location.reload()
            SetIsLogin(true)
            setOpenLogin(false)
        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data.detail || "Erreur de connexion.");
                SetIsLogin(false)
            } else {
                setError("Erreur de connexion.");
                SetIsLogin(false)
            }
        } finally {
            setIsLoading(false);
            SetIsLogin(false)
        }
    };

    const [mobile, SetMobile] = useState(window.innerWidth < 670)
    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 670)
        }

        window.addEventListener('resize', hundleSize)
        return () => window.removeEventListener('resize', hundleSize)

    }, [])

    return (
        <section className="h-full p-3 mb-36">
            <div className={`h-max w-full  p-3 flex ${mobile ? 'flex-col' : ''} gap-5 relative shadow-md  rounded-md  mt-14 border-4 border-dotted border-y-slate-300`}>
                <div className="">
                    <div className="font-bold text-lg sm:text-3xl text-center">Commencer à partager votre Article</div>
                    <p className="md:w-96 w-full sm:p-4  p-2 italic">
                        Veuillez entrer votre e-mail et votre mot de passe si vous avez déjà
                        créé un compte. Si vous n'avez pas de compte, veuillez le créer avec
                        un e-mail réel, cela peut être utile lors de la réinitialisation du
                        mot de passe.
                    </p>
                </div>
                <form
                    className="flex flex-col sm:p-4 p-2 shadow-lg gap-3 rounded-lg  border border-slate-300 w-full "
                    onSubmit={handleSubmit}
                >
                    <div className="font-bold text-2xl">Connexion</div>
                    {error && (
                        <p className="text-red-500">
                            {typeof error === "string"
                                ? error
                                : Object.values(error).map((err, index) => (
                                    <span key={index}>
                                        {err}
                                        <br />
                                    </span>
                                ))}
                        </p>
                    )}
                    {successMessage && <p className="text-green-500">{successMessage}</p>}
                    <input
                        type="text"
                        placeholder="Adresse e-mail..."
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="px-2 py-3 bg-transparent rounded-md outline-none border border-slate-600 focus:border-2 focus:border-blue-500"
                    />
                    <div className="relative">
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Votre mot de passe"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="px-2 py-3  bg-transparent rounded-md w-full outline-none border border-slate-600 focus:border-2 focus:border-blue-500"
                        />
                        <button
                            type="button"
                            className="absolute right-2 top-2 text-gray-500"
                            onClick={() => setShowPassword((prev) => !prev)}
                        >
                            {showPassword ? "Masquer" : "Afficher"}
                        </button>
                    </div>
                    <button
                        type="submit"
                        className="md:px-28 px-2 sm:px-20 text-white py-3 bg-blue-500"
                        disabled={isLoading}
                    >
                        {isLoading ? "Chargement..." : "Se connecter"}
                    </button>
                    <div className="space-x-4 ">
                        <div onClick={() => { setopenforget(true); setOpenLogin(false) }} className="text-blue-500 cursor-pointer underline">
                            Mot de passe oublié
                        </div>
                        <div className="flex items-center flex-wrap">
                            <div>
                                Vous n'avez pas de compte ?
                            </div>
                            <div onClick={() => { setOpenRegi(true); setOpenLogin(false) }} className="text-blue-500 ml-2 cursor-pointer underline">
                                Inscription
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default Login;
