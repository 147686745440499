import React from "react";
import { useThemes } from "../../../UserContext/UserContext";
const SpinnserData = () => {
  const { isDark } = useThemes();
  return (
    <div
      className={`w-full h-full flex left-0 fixed top-0 ${
        isDark === "dark" ? "bg-gray-800" : "bg-gray-50"
      }  overflow-hidden z-[1000]`}
    >
      <div className="flex w-full flex-col items-center justify-center h-screen">
        <div className="text-lg w-[10em] h-[10em] font-bold ">
          <img
            src="../images/ttcv.png"
            className="w-full h-full object-cover"
            alt="   "
          />
        </div>
        <div className="animate-spin rounded-full h-16 w-16 border-b-4 border-blue-500 mb-4"></div>
        <span className="text-lg font-bold text-blue-500">T.T.C.V</span>
        <span
          className={`text-lg font-bold ${
            isDark === "dark" ? "text-gray-100" : "text-gray-900"
          } `}
        >
          <span className="text-blue-500">T</span>oursim{" "}
          <span className="text-blue-500">T</span>ravels &{" "}
          <span className="text-blue-500"> C</span>ulture{" "}
          <span className="text-blue-500">V</span>ision{" "}
        </span>
      </div>
    </div>
  );
};

export default SpinnserData;
