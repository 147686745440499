import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";

// Icons
import { IoSearchOutline } from "react-icons/io5";
import { IoCreateOutline } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { CiCalendarDate } from "react-icons/ci";
import { FaArrowRight } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa6";
import { FaRegComment } from "react-icons/fa";
import { useThemes } from "../../../UserContext/UserContext";
import SpinnserData from "../Pages/SpinnerData";

const VoyageVisitor = ({ categorie }) => {
    const { setOpenLogin, isDark, mobileTop, isLogin } = useThemes();
    const navig = useNavigate()

    // Récupération de l'API
    const [posts, setposts] = useState([]);
    const [pub, setpub] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState(""); // État pour le terme de recherche
    const [isSpinnerData, SetIsSpinnerData] = useState(true)
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(
                    "https://ttcvbackend.eac-advisor.org/posts/posts/"
                );
                const filterItem = response.data.filter(
                    (item) =>
                        item.is_valid === true &&
                        item.is_deleted === false &&
                        item.category === "VOYAGE"
                );
                setposts(filterItem);

                const response1 = await axios.get(
                    "https://ttcvbackend.eac-advisor.org/posts/adds/"
                );
                const filterItem1 = response1.data.filter(
                    (item) => item.is_deleted === false && item.place === "voyage"
                );
                const slidefilterItem = filterItem1.slice(0, 1);
                setpub(slidefilterItem);
                SetIsSpinnerData(false)
            } catch (error) {
                console.error("Erreur lors de la récupération des données", error);
                setError("Erreur de chargement");
            } finally {
                setIsLoading(false);
            }
        };

        fetchPosts();
    }, [categorie]);

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 12;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;

    // Filtrer les posts en fonction du terme de recherche
    const filteredPosts = posts.filter((post) =>
        post.titre.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Obtenir les enregistrements à afficher
    const records = filteredPosts.slice(firstIndex, lastIndex);
    const nPages = Math.ceil(filteredPosts.length / recordsPerPage);
    const numbers = [...Array(nPages + 1).keys()].slice(1);

    // Vérification d'utilisateur connecté pour publier

    return (
        <section className={`w-full  ${isDark === "dark" ? null : 'bg-gray-100'} ${mobileTop ? 'mt-[6vh]' : 'mt-[12vh]'}  p-2  relative  md:max-auto `}>
            {isSpinnerData && <SpinnserData />}
            <div className="bg-gradient-to-r from-slate-500 to-slate-800 rounded-xl sm:h-96 w-full bg-cover bg-center relative">
                {pub.map((value) => (
                    <>
                        {value.images && (
                            <img
                                src={value.images}
                                alt=""
                                className="w-full sm:h-96 rounded-xl object-cover mix-blend-overlay"
                            />
                        )}
                        <div className="absolute max-md:w-full max-sm:text-center md:top-32 md:left-80 top-10 sm:left-20 text-xl md:text-3xl font-bold text-white shadow-lg">
                            {value.titre}
                        </div>
                    </>
                ))}
            </div>

            <div className={`md:text-2xl text-lg  ${isDark === "dark" ? 'text-white' : 'text-black'} sm:mt-6 sm:ml-10`}>
                Articles sur le <span className="italic font-bold">{categorie}</span>:{" "}
                <span>{posts.length}</span> articles
            </div>


            <div className="flex flex-col items-center p-2 mt-7">
                <button className="bg-slate-500 flex items-center hover:scale-105 px-4 rounded-2xl border-2 border-blue-400 py-2">
                    {isLogin ? (
                        <>
                            <div onClick={() => navig("/voyage/publier")} className="flex text-white hover:text-white hover:no-underline  items-center">
                                <span className="mr-2">  Créer un Article</span>
                                <IoCreateOutline />
                            </div>
                        </>
                    ) : (
                        <div onClick={() => setOpenLogin(true)} className="flex text-white hover:text-white hover:no-underline  items-center">
                            <span className="mr-2">  Créer un Article</span>
                            <IoCreateOutline />
                        </div>
                    )}
                </button>
                <div className="flex items-center h-auto  max-md:flex-wrap w-full space-x-6">
                    <form
                        className="flex items-center w-full"
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <input
                            id="text"
                            name="text"
                            placeholder="Rechercher..."
                            className="mt-1 text-black block w-full bg-transparent border border-gray-300 rounded-md focus:border-blue-500 shadow-sm p-2 focus:outline-none"
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                setCurrentPage(1); // Réinitialiser à la première page lors d'une recherche
                            }}
                        />
                    </form>
                    <form className="max-md:w-full ">
                        <select className={`border w-full max-md:mt-2  rounded p-1.5 bg-transparent  border-slate-300  ${isDark === "dark" ? 'text-white' : 'text-black'}`}>
                            <option value="tout" className="text-black">
                                Tout
                            </option>
                            <option value="gratuit" className="text-black">
                                Gratuit
                            </option>
                            <option value="sponsorisé" className="text-black">
                                Sponsorisé
                            </option>
                            <option value="premium" className="text-black">
                                Premium
                            </option>
                        </select>
                    </form>


                </div>
            </div>


            {/* Articles */}
            <div className="grid grid-cols-1 sm:grid-cols-2  m-3 lg:grid-cols-3 gap-2">
                {records.length > 0 ? (
                    records.map((item) => {
                        const desc = `${item.description
                            .split("")
                            .slice(0, 20)
                            .join("")} ...`;
                        const titre = `${item.titre.split("").slice(0, 60).join("")} `;
                        return (
                            <div
                                key={item.id}
                                className="border border-slate-300 shadow-sm w-full h-96 rounded-tl-lg rounded-tr-lg space-x-6"

                            >
                                {item.images && (
                                    <img
                                        src={item.images}
                                        alt=""
                                        className="w-full h-48 object-cover object-top rounded-tl-lg rounded-tr-lg"
                                    />
                                )}
                                <div className={`flex items-center ${isDark === "dark" ? 'text-white' : 'text-black'} space-x-36`}>
                                    <div className="flex items-center space-x-3">
                                        <FaUserCircle />
                                        {item.username}
                                    </div>
                                    <p>{item.pre}</p>
                                </div>
                                <div className={`font-bold  ${isDark === "dark" ? 'text-white' : 'text-black'}`}>
                                    <Link to={`/voyage/detail/${item.id}`}>{titre}</Link>
                                </div>
                                <p className={`w-72 break-words  ${isDark === "dark" ? 'text-white' : 'text-black'}`}>{desc}</p>
                                <p className={`flex items-center space-x-3  ${isDark === "dark" ? 'text-white' : 'text-black'} italic`}>
                                    <CiCalendarDate />
                                    {formatDistanceToNow(new Date(item.created), {
                                        addSuffix: true,
                                    })}
                                </p>
                                <div className="flex items-center space-x-8 mt-4">
                                    <div>
                                        <Link
                                            to={`/voyage/detail/${item.id}`}
                                            className="flex items-center space-x-3"
                                        >
                                            Learn More
                                            <FaArrowRight />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="col-span-full text-center">Aucun résultat trouvé</div>
                )}
            </div>

            {/* HTML pour pagination */}
            <nav>
                <ul className="flex items-center justify-center mt-8">
                    <li className={`px-5 py-2 border ${isDark === "dark" ? 'text-white' : 'text-black'} border-slate-300`}>
                        <button onClick={prePage}>Prev</button>
                    </li>
                    {numbers.map((n, i) => (
                        <li
                            className={`${currentPage === n
                                ? "bg-green-600 px-5 py-2 border border-slate-300"
                                : "px-5 py-2 border border-slate-300"
                                }`}
                            key={i}
                        >
                            <button onClick={() => changeCPage(n)}>{n}</button>
                        </li>
                    ))}
                    <li className={`px-5 py-2 border border-slate-300  ${isDark === "dark" ? 'text-white' : 'text-black'}`}>
                        <button onClick={nextPage}>Next</button>
                    </li>
                </ul>
            </nav>
        </section>
    )

    // Les fonctions pour pagination
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    }
    function nextPage() {
        if (currentPage !== nPages) {
            setCurrentPage(currentPage + 1);
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
}

export default VoyageVisitor