import { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const ConfirmePassWord = () => {
    const { uid, token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess("");

        // Validation du mot de passe
        if (password.length < 8) {
            setError("Le mot de passe doit contenir au moins 8 caractères.");
            return;
        }

        try {
            await axios.post(
                `https://ttcvbackend.eac-advisor.org/accounts/password-reset-confirm/`,
                {
                    uid,
                    token,
                    password,
                }
            );
            setSuccess("Votre mot de passe a été réinitialisé avec succès.");
            setTimeout(() => navigate("/login"), 2000); // Redirige vers la page de connexion après 2 secondes
        } catch (error) {
            const errorMessage =
                error.response?.data?.detail ||
                "Erreur lors de la réinitialisation du mot de passe.";
            setError(errorMessage); // Gestion de l'erreur
        }
    };

    return (
        <>
            <section className="h-full flex mt-[12vh] items-center justify-center mb-36">
                <div className="h-max w-full  md:flex md:items-center relative shadow-md space-x-2 md:space-x-12 md:justify-center mt-14 border-2 rounded-xl border-dotted border-y-slate-300">
                    <form
                        className="flex p-4 items-center justify-center shadow-lg rounded-lg flex-col space-y-3  "
                        onSubmit={handleSubmit}
                    >
                        <div className="font-bold  max-sm:text-center text-xl">Entrer le nouveau mot de passe</div>
                        <input
                            type="email"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Entrez votre nouveau mot de passe"
                            required
                            className={`w-full p-3 rounded-md border bg-transparent border-gray-300 focus:ring-2 focus:ring-blue-500 outline-none  transition`}
                        />
                        <button
                            type="submit"
                            className="md:px-28 px-20 text-white py-3 bg-blue-500"
                        >
                            Confirmer
                        </button>
                        {error && <p className="text-red-500">{error}</p>}{" "}
                        {/* Affichage de l'erreur */}
                        {success && <p className="text-green-500">{success}</p>}{" "}
                        {/* Affichage du succès */}
                        <div className="flex items-center space-x-4">
                            <p className="break-words w-80">
                                Entrez votre nouveau mot de passe.
                            </p>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};

export default ConfirmePassWord;
