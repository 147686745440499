import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Mail, Info, Edit3, Lock } from "lucide-react"; // Ajoutez les icônes nécessaires
import { toast } from "react-toastify";
import { useThemes } from "../../../UserContext/UserContext";

const Register = () => {
    const navigate = useNavigate();
    const { setOpenRegi, setOpenLogin, isDark } = useThemes()

    const [formData, setFormData] = useState({
        username: "",
        first_name: "",
        last_name: "",
        profession: "",
        country: "",
        email: "",
        password1: "",
        password2: "",
        sexe: "",
    });


    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [error, setError] = useState(null);

    const handle = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        const { password1, password2, email, username } = formData;
        console.log("password1, password2, email, username ", password1, password2, email, username)
        if (!username || !email || !password1 || !password2) {
            toast.error("Tous les champs doivent être remplis.")
            return "Tous les champs doivent être remplis.";
        }
        if (password1 !== password2) {
            return "Les mots de passe ne correspondent pas.";
        }
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) return;

        const validationError = validateForm();
        if (validationError) {
            setError(validationError);
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            await axios.post(
                "https://ttcvbackend.eac-advisor.org/accounts/register/",
                formData
            );
            setSuccessMessage("Inscription réussie !");

            toast.success("Inscription réussie !")
            setOpenRegi(false)
            setOpenLogin(true)
        } catch (error) {
            console.error("Erreur de publication", error);
            if (error.response) {
                const errorMessage =
                    error.response.data.detail || JSON.stringify(error.response.data);
                setError(`Erreur: ${errorMessage}`);
            } else {
                setError("Erreur dans la configuration de la requête.");
            }
        } finally {
            setIsLoading(false);
        }
    };


    const [mobile, SetMobile] = useState(window.innerWidth < 1000)
    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 1000)
        }

        window.addEventListener('resize', hundleSize)
        return () => window.removeEventListener('resize', hundleSize)

    }, [])

    return (
        <section className="h-full  w-full pb-20">
            <div className={`w-full flex  justify-center gap-2 ${mobile ? 'flex-col' : ''}  space-y-2 shadow-lg  rounded-md p-2`}>

                <div className={`p-4 ${mobile ? 'w-full' : 'w-[40%]'}  ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'}`}>
                    <h1 className="font-bold text-lg mb-4">À connaître</h1>
                    <ul className="space-y-4">
                        <li className="flex items-start space-x-3">
                            <Mail className="text-blue-500 w-6 h-6" />
                            <span>Veuillez entrer un e-mail valide.</span>
                        </li>
                        <li className="flex items-start space-x-3">
                            <Info className="text-blue-500 w-6 h-6" />
                            <span>
                                Si vous rencontrez des problèmes, contactez-nous dans le menu
                                messages.
                            </span>
                        </li>
                        <li className="flex items-start space-x-3">
                            <Edit3 className="text-blue-500 w-6 h-6" />
                            <span>
                                Ce site est conçu pour partager des informations (articles) dans
                                diverses catégories.
                            </span>
                        </li>
                        <li className="flex items-start space-x-3">
                            <Info className="text-blue-500 w-6 h-6" />
                            <span>Pour supprimer un article publié, contactez-nous.</span>
                        </li>
                        <li className="flex items-start space-x-3">
                            <Edit3 className="text-blue-500 w-6 h-6" />
                            <span>
                                Les articles sont analysés avant d'apparaître au public.
                            </span>
                        </li>
                        <li className="flex items-start space-x-3">
                            <Info className="text-blue-500 w-6 h-6" />
                            <span>Vous devez avoir un compte pour commenter.</span>
                        </li>
                    </ul>
                </div>





                {/* Formulaire d'inscription */}
                <form
                    className={`flex  items-center justify-center shadow-lg rounded-lg flex-col space-y-4 border ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} border-slate-300 ${mobile ? 'w-full' : 'w-[60%]'} p-2`}
                    onSubmit={handleSubmit}
                >
                    <img
                        src="../images/ttcv.png"
                        alt=""
                        className="w-24 h-24"
                    />
                    <h1 className="font-bold text-2xl mb-4">
                        Inscription
                    </h1>

                    {error && <p className="text-red-500">{error}</p>}
                    {successMessage && <p className="text-green-500">{successMessage}</p>}

                    {[
                        "last_name",
                        "first_name",
                        "username",
                        "profession",
                        "country",
                        "email",
                    ].map((field) => (
                        <div key={field} className="w-full">
                            <label
                                htmlFor={field}
                                className="block mb-1"
                            >
                                {`Votre ${field.replace("_", " ")}`}
                            </label>
                            <input
                                type="text"
                                name={field}
                                placeholder={`Votre ${field.replace("_", " ")}...`}
                                value={formData[field]}
                                onChange={handle}
                                className="w-full p-3 rounded-md border bg-transparent border-gray-300 focus:ring-2 focus:ring-blue-500 outline-none  transition"
                                required
                            />
                        </div>
                    ))}

                    <div className="w-full">
                        <label
                            htmlFor="password1"
                            className="block mb-1  "
                        >
                            Mot de passe
                        </label>
                        <input
                            type="password"
                            name="password1"
                            placeholder="Votre mot de passe..."
                            value={formData.password1}
                            onChange={handle}
                            className={`w-full p-3 rounded-md border bg-transparent border-gray-300 focus:ring-2 focus:ring-blue-500 outline-none  transition`}
                            required
                        />
                    </div>

                    <div className="w-full">
                        <label
                            htmlFor="password2"
                            className="block mb-1  "
                        >
                            Confirmer le mot de passe
                        </label>
                        <input
                            type="password"
                            name="password2"
                            placeholder="Confirmer le mot de passe..."
                            value={formData.password2}
                            onChange={handle}
                            className="w-full p-3 rounded-md bg-transparent border border-gray-300 focus:ring-2 focus:ring-blue-500 outline-none  transition"
                            required
                        />
                    </div>

                    <div className="w-full  mb-2">
                        <p className="mb-2">Sexe:</p>
                        <div className="flex items-center space-x-7">
                            <label className="">
                                <input
                                    type="radio"
                                    name="sexe"
                                    value="Homme"
                                    checked={formData.sexe === "Homme"}
                                    onChange={handle}
                                    className="mr-2"
                                />
                                Homme
                            </label>
                            <label className=" ">
                                <input
                                    type="radio"
                                    name="sexe"
                                    value="Femme"
                                    checked={formData.sexe === "Femme"}
                                    onChange={handle}
                                    className="mr-2"
                                />
                                Femme
                            </label>
                        </div>
                    </div>

                    <button
                        type="submit"
                        className={`w-full md:w-3/4 px-4 py-3 rounded-md text-white bg-green-500 hover:bg-green-600 focus:bg-green-700 transition ${isLoading && "opacity-50 cursor-not-allowed"
                            }`}
                        disabled={isLoading}
                    >
                        {isLoading ? "Inscription en cours..." : "S'inscrire"}
                    </button>

                    <div className="flex w-full">
                        <div>Si vous avez un complte</div>
                        <div onClick={() => { setOpenLogin(true); setOpenRegi(false) }} className="text-blue-500  ml-3 cursor-pointer">Se connecter ici</div>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default Register;
