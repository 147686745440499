import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";


const ForgetPassword = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { setopenforget, setOpenLogin } = useThemes()


    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");
        setError("");

        // Validation de l'email
        if (!email) {
            setError("L'email est requis.");
            return;
        }

        // Vérification du format de l'email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setError("Veuillez entrer une adresse email valide.");
            return;
        }

        try {
            const response = await axios.post(
                "https://ttcvbackend.eac-advisor.org/accounts/password-reset/",
                { email }
            );
            setMessage(
                "Un lien de réinitialisation du mot de passe a été envoyé à votre adresse mail."
            );
        } catch (error) {
            const errorMessage =
                error.response?.data?.detail || "Erreur lors de l'envoi de l'email.";
            setError(errorMessage);
        }
    };

    return (
        <>
            <section className="h-full flex items-center justify-center mb-36">
                <div className="h-max w-full  md:flex md:items-center relative shadow-md space-x-2 md:space-x-12 md:justify-center mt-14 border-2 rounded-xl border-dotted border-y-slate-300">
                    <form
                        className="flex p-4 items-center justify-center shadow-lg rounded-lg flex-col space-y-3  "
                        onSubmit={handleSubmit}
                    >

                        <input
                            type="email"
                            placeholder="Entrez votre email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className={`w-full p-3 rounded-md border bg-transparent border-gray-300 focus:ring-2 focus:ring-blue-500 outline-none  transition`}
                        />
                        <button
                            type="submit"
                            className=" w-full text-white py-3 bg-blue-500"
                        >
                            Envoyer
                        </button>
                        <div className="w-full  flex justify-between items-center">
                            <div className="underlne text-green-500 cursor-pointer text-xl " onClick={() => { setopenforget(false) }}>Ok</div>
                            <div className="underlne text-blue-500 cursor-pointer text-xl " onClick={() => { setopenforget(false); setOpenLogin(true) }}>retour</div>
                        </div>
                        {message && <p className="text-green-500">{message}</p>}
                        {error && <p className="text-red-500">{error}</p>}
                        <div className="flex items-center space-x-4">
                            <p className="break-words w-80">
                                Le lien de réinitialisation du mot de passe sera envoyé à
                                l'adresse e-mail que vous entrez. Assurez-vous d'entrer une
                                adresse e-mail que vous avez utilisée lors de la création de
                                votre compte.
                            </p>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};

export default ForgetPassword;
