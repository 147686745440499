
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";

//icons
import { FaRegUser } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";
import { MdOutlineDateRange } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa6";
import { useThemes } from "../../../UserContext/UserContext";
import SpinnserData from "../Pages/SpinnerData";


const VoyageVisiorHomme = () => {
    //recuperation des donnees
    const [posts, setposts] = useState([]);
    const [isloading, setisloading] = useState(true);
    const [error, seterror] = useState(null);
    const { isDark } = useThemes()
    const [isSpinnerData, SetIsSpinnerData] = useState(true)

    useEffect(() => {
        const fetchpost = async () => {
            try {
                const response = await axios.get(
                    "https://ttcvbackend.eac-advisor.org/posts/posts/"
                );
                const filterItem = response.data.filter(
                    (item) =>
                        item.is_valid === true &&
                        item.is_deleted === false &&
                        item.category === "VOYAGE"
                );
                const slidefilterItem = filterItem.slice(0, 4);
                setposts(slidefilterItem);
                SetIsSpinnerData(false)
            } catch (error) {
                console.error("Erreur lors de la recuperation des donnees", error);
                seterror("Erreur de chargement");
            } finally {
                setisloading(false);
            }
        };

        fetchpost();
    }, []);
    return (
        <>
            {isSpinnerData && <SpinnserData />}
            <section data-aos="fade-down-left" className={`w-full mt-10 relative ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} p-2  md:max-auto `}>
                <hr />
                <div className="absolute flex  w-full justify-center ">
                    <div className="text-center  rounded-xl bg-green-600 sm:text-[2em]  font-extrabold  text-white sm:px-7 px-5  py-2">
                        Voyage
                    </div>
                </div>

                <div className="border-slate-500 mt-1">
                    {/*premier partie*/}
                    <div className="grid grid-cols-1 sm:grid-cols-2  m-3 lg:grid-cols-3 gap-2">
                        {posts.map((item) => {
                            const desc = `${item.description
                                .split("")
                                .slice(0, 70)
                                .join("")}...`;
                            const titre = `${item.titre.split("").slice(0, 150).join("")} `;
                            return (
                                <div
                                    key={item.id}
                                    className=" ml-[-10px]  md:p-2   md:space-y-4 "
                                >
                                    <div className="w-full  sm:h-96 rounded-lg">
                                        {item.images && (
                                            <img
                                                src={item.images}
                                                alt=""
                                                className="h-full object-cover object-top rounded-lg w-full"
                                            />
                                        )}
                                    </div>
                                    <div className="md:w-96 xl:w-96 w-full p-3">
                                        <div className="font-bold w-72 break-words  md:text-2xl">
                                            <Link className="hover:no-underline focus:no-underline" to={`/voyage/detail/${item.id}`}>{titre}</Link>
                                        </div>
                                        <p className="text-gray-400 break-words  w-72">
                                            {desc}{" "}
                                            <Link
                                                to={`/voyage/detail/${item.id}`}
                                                className="uhover:no-underline focus:no-underline"
                                            >
                                                voir plus
                                            </Link>
                                        </p>
                                        <p className={`italic ${isDark === "dark" ? 'text-gray-300' : 'text-gray-900'}`}>
                                            Tags:
                                            <span className={`bg-green-500 px-3 py-0  text-white `}>
                                                {item.category}
                                            </span>
                                        </p>
                                        <div className={`flex items-center ${isDark === "dark" ? 'text-gray-300' : 'text-gray-900'} space-x-4`}>
                                            <p className="flex items-center ">
                                                <FaRegUser className="m-2" />
                                                {item.username}
                                            </p>
                                            <p className="flex items-center ">
                                                <MdBusinessCenter className="m-2" />
                                                {item.profession}
                                            </p>
                                        </div>
                                        <p className={`flex items-center ${isDark === "dark" ? 'text-gray-300' : 'text-gray-900'}`}>
                                            <MdOutlineDateRange className="m-2" />
                                            {formatDistanceToNow(new Date(item.created), {
                                                addSuffix: true,
                                            })}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div data-aos="flip-left" className="mr-14 w-full flex justify-end">
                    <Link
                        to="voyage"
                        className="flex items-center text-lg text-blue-400 underline"
                    >
                        Voir plus des articles
                        <FaArrowRight />
                    </Link>
                </div>
            </section>
        </>
    )
}

export default VoyageVisiorHomme