import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SpinnserData from "../../Visiteurs/Pages/SpinnerData";
import SlideBarsAdmin from "../SlideBarsAdmin/SlideBarsAdmin";
import NavBarsAdmin from "../NavBarsAdmin/NavBarsAdmin";
import { useThemes } from "../../../UserContext/UserContext";
import { CustomProvider } from "rsuite";


const LayoutAdmin = () => {
    const [username, setusername] = useState("");
    const [Loggedin, SetIsLogin] = useState(false);
    const [isSpinnerData, setisSpinnerData] = useState(true);
    const [error, seterror] = useState("");
    const navigate = useNavigate();
    const { isDark, mobile } = useThemes()

    // useEffect(() => {

    //     const checkLoggedInuser = async () => {
    //         try {
    //             const token = localStorage.getItem("accessToken");

    //             if (token) {
    //                 const config = {
    //                     headers: {
    //                         Authorization: `Bearer ${token}`,
    //                     },
    //                 };
    //                 const response = await axios.get(
    //                     "https://ttcvbackend.eac-advisor.org/accounts/user/",
    //                     config
    //                 );
    //                 SetIsLogin(true);
    //                 setusername(response.data.username);
    //                 setisSpinnerData(false)
    //             } else {
    //                 SetIsLogin(false);
    //                 setisSpinnerData(false)
    //                 setusername("");
    //                // navigate("/loginA");
    //             }
    //         } catch (error) {
    //             SetIsLogin(false);
    //             setusername("");
    //         }
    //     };
    //     checkLoggedInuser();
    // }, []);



    const [mobile2, setMobile2] = useState(window.innerWidth < 500)

    const [mobile1, setMobile1] = useState(window.innerWidth < 444)
    const [mobile3, setMobile3] = useState(window.innerWidth < 322)
    const [isOpened, setisOpened] = useState(false)

    useEffect(() => {
        const handleSize = () => {
            setMobile2(window.innerWidth < 500);

            setMobile1(window.innerWidth < 444);
            setMobile3(window.innerWidth < 322);
            setisOpened(false)
        };

        window.addEventListener('resize', handleSize);
        return () => {
            window.removeEventListener('resize', handleSize);
        };
    }, []);


    return (
        <>
            <CustomProvider theme={isDark}>
                <div className={`flex w-full ${isDark === "dark" ? null : 'bg-gray-100'} h-screen overflow-hidden`}>
                    {!mobile2 && <SlideBarsAdmin />}
                    <div className={`${mobile ? mobile2 ? 'w-full' : 'w-[93%] ' : 'w-[80%]'} overflow-x-hidden ${isDark === "dark" ? null : 'bg-gray-100'} h-screen overflow-y-auto`}>
                        <NavBarsAdmin />
                        <Outlet />
                    </div>
                </div>
            </CustomProvider >
            {isSpinnerData && <SpinnserData />
            }


        </>
    );
};

export default LayoutAdmin;
