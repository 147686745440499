
import React, { useEffect, useState } from "react";
import Carousel from 'react-multi-carousel';
import axios from "axios";
import Typewriter from "typewriter-effect";
import SpinnserData from "../Pages/SpinnerData";


function Sloger() {
  const [mobile, setMobile] = useState(window.innerWidth < 1092)
  const [mobile1, setMobile1] = useState(window.innerWidth < 1074)
  const [mobile2, setMobile2] = useState(window.innerWidth < 729)

  useEffect(() => {
    const handleSize = () => {
      setMobile(window.innerWidth < 1092);
      setMobile1(window.innerWidth < 1074);
      setMobile2(window.innerWidth < 729);
    };

    window.addEventListener('resize', handleSize);
    return () => {
      window.removeEventListener('resize', handleSize);
    };
  }, []);




  const CustomRightArrow = ({ onClick }) => {
    return (
      <div className='absolute flex justify-center items-center z-[1000] right-5 h-full'>
        <button
          onClick={onClick}
          className=' bg-[#3d8fdb] border-none p-[5px] cursor-pointer text-white  rounded-full'
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
          </svg>
        </button>
      </div>
    );
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <div className='absolute flex justify-center items-center z-[1000] h-full'>
        <button
          onClick={onClick}
          className='bg-[#3d8fdb]  border-none p-[5px] cursor-pointer text-white left-2 rounded-full'
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
            <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
          </svg>
        </button>
      </div>
    );
  };


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 40000, min: 280 },
      items: 1
    }
  };



  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinnerData, SetIsSpinnerData] = useState(true)
  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response1 = await axios.get(
          "https://ttcvbackend.eac-advisor.org/posts/adds/"
        );
        const filterItem = response1.data.filter(
          (item) => item.is_deleted === false && item.place === "accueil"
        );
        SetIsSpinnerData(false)
        setPosts(filterItem);
      } catch (error) {
        console.error("Erreur lors de la récupération des publicités", error);
        setError("Erreur lors d'affichage des publicités");
      } finally {
        setIsLoading(false);
      }
    };
    fetchAds();
  }, []);


  console.log("posts", posts)

  return (
    <>
      {isSpinnerData && <SpinnserData />}
      <Carousel
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        responsive={responsive}
        autoPlay
        infinite
        autoPlaySpeed={5000}
        additionalTransfrom={0}
        containerClass={` ${mobile2 ? 'h-auto' : 'h-[80vh]'} bg-gradient-to-r relative  rounded-xl`}
      >
        {posts && posts.length > 0 && posts.map((data, index) => {
          const desc = `${data.content
            .split("")
            .slice(0, 30)
            .join("")}...`;
          const isValidLink =
            data.link &&
            (data.link.startsWith("http://") ||
              data.link.startsWith("https://"));

          return (
            <div data-position={index} key={data.id} className='rounded-md  overflow-hidden'>
              <div className='h-full w-full '>
                <img
                  src={data.images}
                  alt={data.name}
                  className='w-full h-full object-cover object-center' />

                <div className={`sm:p-24 z-50  filter w-full h-full bg-[#0000004f]  absolute   ${mobile2 ? 'top-[0%]' : 'top-[20%]'} text-white p-4`}>
                  <div data-aos='fade-up' className="md:text-4xl sm:text-lg xl:text-4xl font-bold">
                    {data.titre}
                  </div>
                  <p data-aos='fade-down' className="md:text-2xl text-white xl:text-2xl font-light mt-5">
                    <Typewriter
                      options={{
                        strings: [`${desc}`],
                        autoStart: true,
                        autoStartSpeed: 300,
                        loop: true,
                      }}
                    />
                  </p>
                  {isValidLink ? (
                    <>
                      <a
                        data-aos='fade-left'
                        href={`"${data.link}"`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-red-600 text-white rounded-md mt-4  flex items-center w-28 px-5 py-2 cursor-pointer" // Assurez-vous que cursor-pointer est ici
                      >

                        <span>Visiter</span>

                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-right animate-pulse ml-2" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                        </svg>
                      </a>
                      <div className="ml-2 mt-4 animate-bounce">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hand-index-thumb-fill" viewBox="0 0 16 16">
                          <path d="M8.5 1.75v2.716l.047-.002c.312-.012.742-.016 1.051.046.28.056.543.18.738.288.273.152.456.385.56.642l.132-.012c.312-.024.794-.038 1.158.108.37.148.689.487.88.716q.113.137.195.248h.582a2 2 0 0 1 1.99 2.199l-.272 2.715a3.5 3.5 0 0 1-.444 1.389l-1.395 2.441A1.5 1.5 0 0 1 12.42 16H6.118a1.5 1.5 0 0 1-1.342-.83l-1.215-2.43L1.07 8.589a1.517 1.517 0 0 1 2.373-1.852L5 8.293V1.75a1.75 1.75 0 0 1 3.5 0" />
                        </svg></div>

                    </>
                  ) : (
                    <span className="bg-gray-600 flex items-center px-5 py-2 text-white">
                      Pas de lien disponible
                    </span>
                  )}
                </div>

              </div>
            </div>
          )
        })}
      </Carousel>
    </>
  )
}

export default Sloger