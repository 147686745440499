import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { marked } from "marked";
import { useThemes } from "../../../UserContext/UserContext";


const PublicAdmin = () => {
    const { isDark } = useThemes()

    const [formData, setFormData] = useState({
        titre: "",
        description: "",
        category: "",
        images: null,
        lieu: "",
    });

    const [dataForm, setDataForm] = useState({
        titre: "",
        content: "",
        category: "",
        images: null,
        link: "",
        place: "",
    });

    const [markdown, setMarkdown] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [Erreur, setErreur] = useState(null);
    const [Success, setSuccessSend] = useState(false);

    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [loggedIn, setLoggedIn] = useState(false);

    // Check if user is logged in
    useEffect(() => {
        const checkLoggedInUser = async () => {
            try {
                const token = localStorage.getItem("accessToken");
                if (token) {
                    const config = { headers: { Authorization: `Bearer ${token}` } };
                    const response = await axios.get("https://ttcvbackend.eac-advisor.org/accounts/user/", config);
                    setLoggedIn(true);
                    setUsername(response.data.username);
                } else {
                    //  navigate("/loginA");
                }
            } catch {
                // navigate("/loginA");
            }
        };
        checkLoggedInUser();
    }, [navigate]);

    // Image preview states
    const [imagePreview, setImagePreview] = useState("");
    const [imagePreview1, setImagePreview1] = useState("");

    const handle = (e) => {
        const { name, value, type, files } = e.target;

        if (files) {
            setFormData({ ...formData, [name]: files[0] });
            setImagePreview(URL.createObjectURL(files[0]));
        } else {
            setFormData({ ...formData, [name]: value });
        }

        if (name === "description") {
            setMarkdown(value);
        }
    };

    const handleS = (e) => {
        const { name, value, type, files } = e.target;

        if (files) {
            setDataForm({ ...dataForm, [name]: files[0] });
            setImagePreview1(URL.createObjectURL(files[0]));
        } else {
            setDataForm({ ...dataForm, [name]: value });
        }
    };

    const validateForm = (data) => {
        if (!data.titre || !markdown || !data.description || !data.category || !data.images || !data.lieu) {
            return "Tous les champs doivent être remplis.";
        }
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!loggedIn) {
            setError("Vous devez être connecté pour publier");
            return;
        }

        const formError = validateForm(formData);
        if (formError) {
            setError(formError);
            return;
        }

        setIsLoading(true);
        setError(null);
        setSuccess(false);

        const token = localStorage.getItem("accessToken");

        try {
            const data = new FormData();
            data.append("titre", formData.titre);
            data.append("description", formData.description);
            data.append("category", formData.category);
            data.append("lieu", formData.lieu);
            if (formData.images) {
                data.append("images", formData.images);
            }

            await axios.post("https://ttcvbackend.eac-advisor.org/posts/posts/", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            });

            setSuccess(true);
            setFormData({ titre: "", description: "", category: "", images: null, lieu: "" });
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const validateDataForm = (data) => {
        if (!data.titre || !data.content || !data.link || !data.images || !data.place) {
            return "Tous les champs doivent être remplis.";
        }
        const linkPattern = /^https?:\/\/(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([\/\w .-]*)*\/?$/;
        if (data.link && !linkPattern.test(data.link)) {
            return "Veuillez entrer un lien valide.";
        }
        return null;
    };

    const handleSend = async (e) => {
        e.preventDefault();
        if (!loggedIn) {
            setErreur("Vous devez être connecté pour publier");
            return;
        }

        const dataFormError = validateDataForm(dataForm);
        if (dataFormError) {
            setErreur(dataFormError);
            return;
        }

        if (dataForm.link && !/^https?:\/\//i.test(dataForm.link)) {
            dataForm.link = "http://" + dataForm.link;
        }

        setIsLoading(true);
        setErreur(null);
        setSuccessSend(false);

        const token = localStorage.getItem("accessToken");

        try {
            const data = new FormData();
            data.append("titre", dataForm.titre);
            data.append("content", dataForm.content);
            data.append("category", dataForm.category);
            data.append("link", dataForm.link);
            data.append("place", dataForm.place);
            if (dataForm.images) {
                data.append("images", dataForm.images);
            }

            await axios.post("https://ttcvbackend.eac-advisor.org/posts/adds/", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            });

            setSuccessSend(true);
            setDataForm({ titre: "", content: "", category: "", images: null, link: "", place: "" });
        } catch (error) {
            handleError(error, true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleError = (error, isAd = false) => {
        console.error("Erreur de publication", error);
        const errorMessage = error.response?.data?.detail || "Une erreur inconnue est survenue.";
        if (isAd) {
            setErreur(`Erreur: ${errorMessage}`);
        } else {
            setError(`Erreur: ${errorMessage}`);
        }
    };

    return (
        <section className={`w-full ${isDark === "dark" ? null : 'bg-gray-100'} p-2 relative md:max-auto`}>
            <div className="h-full w-full  relative shadow-md   mt-5 border-4 border-dotted border-y-slate-300">

                {/* Publication d'un article */}
                <form onSubmit={handleSubmit} className={`flex p-3 items-center justify-center ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} sm:p-10 shadow-lg rounded-lg flex-col  border border-slate-300 w-full h-full`} encType="multipart/form-data">
                    <h1 className="font-bold text-2xl text-blue-500">Publication d'un article</h1>

                    <label htmlFor="titre" className="font-bold">
                        Titre d'article
                    </label>
                    <input
                        type="text"
                        name="titre"
                        value={formData.titre}
                        onChange={handle}
                        placeholder="Votre titre..."
                        className="border-2 border-blue-500 mb-5 block w-full p-3  bg-transparent rounded-md focus:outline-none focus:border-blue-400"
                        required
                    />

                    <label htmlFor="lieu" className="font-bold">
                        Ajouter un lieu (pays + ville)
                    </label>
                    <input
                        type="text"
                        name="lieu"
                        value={formData.lieu}
                        onChange={handle}
                        placeholder="Entrer le lieu d'article..."
                        className="border-2 mb-5 border-blue-500 w-full p-3 bg-transparent rounded-md focus:outline-none focus:border-blue-400"
                        required
                    />

                    <label htmlFor="images" className="border-2 mb-5 cursor-pointer border-blue-500 w-full p-3 bg-transparent rounded-md focus:outline-none  focus:border-blue-400">
                        Ajouter une image:
                    </label>
                    <input
                        type="file"
                        name="images"
                        id="images"
                        onChange={handle}
                        hidden
                        required
                    />

                    {imagePreview && (
                        <div className="w-[10em] mb-2 rounded-md border overflow-hidden h-[10em]">
                            <div>{formData.images && formData.images.name}</div>
                            <div>
                                <img className="w-full h-[8em] object-contain object-center" src={imagePreview} alt="Aperçu" />
                            </div>
                        </div>
                    )}

                    <label htmlFor="description" className="font-bold">
                        Description d'article (Markdown):
                    </label>
                    <textarea
                        placeholder="Ajouter une description en Markdown..."
                        value={markdown}
                        name="description"
                        onChange={handle}
                        className="border-2 border-blue-500 mb-5 w-full bg-transparent rounded-md focus:outline-none focus:border-blue-400 min-h-[10em] max-h-[20em]  p-3"
                        required
                    ></textarea>
                    <div className="border p-4 rounded-lg mb-4 prose" dangerouslySetInnerHTML={{ __html: marked(markdown) }} />

                    <p className="font-bold">Choisir la catégorie de votre publication :</p>
                    <div className="flex sm:items-center max-sm:flex-col gap-1 justify-start ms:justify-between w-full">
                        {["CULTURE", "VOYAGE", "TOURISME"].map((category) => (
                            <label key={category} className="cursor-pointer ">
                                <input
                                    type="radio"
                                    name="category"
                                    value={category}
                                    checked={formData.category === category}
                                    onChange={handle}
                                />
                                {category}
                            </label>
                        ))}
                    </div>

                    <button type="submit" className="text-white w-full mt-4 py-3 bg-slate-500" disabled={isLoading}>
                        {isLoading ? "Publication en cours..." : "Publier un article"}
                    </button>
                    {error && <p className="text-red-500">{error}</p>}
                    {success && <p className="text-green-500">Publication réussie!</p>}
                </form>

                {/* Publication d'une publicité */}
                <form onSubmit={handleSend} className={`flex p-3 mt-5 items-center justify-center ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} sm:p-10 shadow-lg rounded-lg flex-col  border border-slate-300 w-full h-full`} encType="multipart/form-data">
                    <h1 className="font-bold text-2xl text-blue-500">Publication d'une publicité</h1>

                    <label htmlFor="titre" className="font-bold">Titre de publicité</label>
                    <input
                        type="text"
                        name="titre"
                        value={dataForm.titre}
                        onChange={handleS}
                        placeholder="Votre titre..."
                        className="border-2 border-blue-500 mb-5 block w-full p-3  bg-transparent rounded-md focus:outline-none focus:border-blue-400"
                        required
                    />

                    <label htmlFor="image" className="border-2 mb-5 cursor-pointer border-blue-500 w-full p-3 bg-transparent rounded-md focus:outline-none  focus:border-blue-400">
                        Ajouter une image:
                    </label>
                    <input
                        type="file"
                        name="images"
                        id="image"
                        onChange={handleS}
                        hidden
                        required
                    />

                    {imagePreview1 && (
                        <div className="w-[10em] mb-2 rounded-md border overflow-hidden h-[10em]">
                            <div>{dataForm.images && dataForm.images.name}</div>
                            <div>
                                <img className="w-full h-[8em] object-contain object-center" src={imagePreview1} alt="Aperçu" />
                            </div>
                        </div>
                    )}

                    <label htmlFor="link" className="font-bold break-words">Ajouter un lien vers un site web : exemple https://www.ttcv.com</label>
                    <input
                        type="text"
                        name="link"
                        value={dataForm.link}
                        onChange={handleS}
                        placeholder="Votre lien exemple (www.google.com) ..."
                        className="border-2 border-blue-500 mb-5 block w-full p-3  bg-transparent rounded-md focus:outline-none focus:border-blue-400"
                        required
                    />

                    <label htmlFor="content" className="font-bold">Description de votre publicité:</label>
                    <textarea
                        placeholder="Publier votre petite description de votre publicité ..."
                        value={dataForm.content}
                        name="content"
                        onChange={handleS}
                        className="border-2 border-blue-500 mb-5 w-full bg-transparent rounded-md focus:outline-none focus:border-blue-400 min-h-[10em] max-h-[20em]  p-3"
                        required
                    ></textarea>

                    <p className="font-bold">Où voulez-vous que votre publicité apparaisse ? :</p>
                    <div className="flex sm:items-center max-sm:flex-col gap-1 justify-start ms:justify-between w-full">
                        {["accueil", "culture", "tourisme", "voyage", "centre"].map((place) => (
                            <label key={place} className="p-2 cursor-pointer">
                                <input
                                    type="radio"
                                    name="place"
                                    value={place}
                                    checked={dataForm.place === place}
                                    onChange={handleS}
                                />
                                {place}
                            </label>
                        ))}
                    </div>

                    <button type="submit" className="w-full mt-1 text-white py-3 bg-slate-500" disabled={isLoading}>
                        {isLoading ? "Publication en cours..." : "Publier une publicité"}
                    </button>
                    {Erreur && <p className="text-red-500">{Erreur}</p>}
                    {Success && <p className="text-green-500">Publication réussie!</p>}
                </form>
            </div>
        </section>
    );
};

export default PublicAdmin;
