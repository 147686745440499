import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";

const PubliciteList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [posts, setPosts] = useState([]);
    const navigate = useNavigate();
    const { isDark } = useThemes()

    useEffect(() => {
        const recuperer = async () => {
            try {
                const response = await axios.get(
                    "https://ttcvbackend.eac-advisor.org/posts/adds/"
                );
                setPosts(response.data);
            } catch (error) {
                console.error("Erreur lors de la recuperation des publicités", error);
                setError("Erreur lors de la recuperation des publicités");
            } finally {
                setIsLoading(false);
            }
        };
        recuperer();
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const itemsPerPage = 5;

    const filteredData = posts.filter((item) =>
        item.titre.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    // Handle deletion of the publicite
    const handleDelete = async (id) => {
        try {
            await axios.patch(
                `https://ttcvbackend.eac-advisor.org/posts/adds/${id}/`,
                { is_deleted: true }
            );
            alert("Publicité supprimée avec succès !");
            navigate("/admin/dashboard"); // Redirect to the posts list after deletion
        } catch (error) {
            console.error("Erreur lors de la suppression", error);
            setError("Erreur lors de la suppression");
        }
    };

    return (
        <section className={`w-full ${isDark === "dark" ? null : 'bg-gray-100'} p-2 relative md:max-auto`}>
            <div>
                <div className="font-bold m-4 md:text-2xl">
                    La gestion des publicités est ici :
                </div>

                <input
                    type="text"
                    placeholder="Rechercher par titre..."
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        setCurrentPage(1); // Reset to the first page when searching
                    }}
                    className="mb-4 p-2 bg-transparent border rounded"
                />

                <div className="overflow-y-hidden overflow-x-auto">
                    <table className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} border border-black w-full h-full`}>
                        <thead className="">
                            <tr className="bg-blue-500  text-white uppercase">
                                <th className="p-3 text-left border-r">ID</th>
                                <th className="p-3 text-left border-r">Titre</th>
                                <th className="p-3 text-left border-r">Date</th>
                                <th className="p-3 text-left border-r">Catégorie</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((publicite) => (
                                    <tr
                                        key={publicite.id}
                                        className="border-b border-gray-400 hover:bg-gray-200"
                                    >
                                        <td className="p-3 text-nowrap  text-left border-r border-gray-400">
                                            {publicite.id}
                                        </td>
                                        <td className="p-3 text-nowrap text-left border-r border-gray-400 text-blue-500 underline">
                                            <Link to={`${publicite.id}`}>{publicite.titre}</Link>
                                        </td>
                                        <td className="p-3 text-nowrap text-left border-r border-gray-400">
                                            {new Date(publicite.created).toLocaleDateString("en-CA")}
                                        </td>
                                        <td className="p-3 text-nowrap text-left border-r border-gray-400">
                                            {publicite.place}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="py-3 text-center">
                                        Aucune publicité trouvée
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="flex justify-center mt-4">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => paginate(index + 1)}
                            className={`mx-1 px-4 py-2 border rounded ${currentPage === index + 1
                                ? "bg-blue-500 text-white"
                                : "bg-white text-blue-500"
                                }`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default PubliciteList;
