import React, { useState } from 'react'

import { Link } from "react-router-dom";

//icons
import { FaRegUser } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";
import { MdOutlineDateRange } from "react-icons/md";
import { RiRectangleFill } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa6";

import { HelpCircle } from "lucide-react";
import { useThemes } from '../../../UserContext/UserContext';

function ExploiteHomeVisitor({ VideoID }) {
    const { isDark } = useThemes()

    const [isSpinnerData, SetIsSpinnerData] = useState(true)


    return (
        <>

            <section

                data-aos="fade-up"
                data-aos-duration="2000"
                className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} p-2 w-full mt-10`}>
                <div className={`text-center sm:text-3xl text-lg   ${isDark === "dark" ? 'text-gray-300' : 'text-gray-400'} px-7 py-2`}>Explorer plus</div>
                <div className="w-full flex justify-center">
                    {/*premier partie*/}
                    <div className="mt-5 mb-1" >
                        <p className={`italic font-bold ${isDark === "dark" ? 'text-gray-300' : 'text-gray-400'}`}>Commencer a partager votre experience dans le tourisme et culture & voyage partout dans le monde en creant votre article ici commencer avec la creation du compte en haut !</p>
                        <iframe
                            className="w-full h-auto sm:h-[80vh]"
                            src={`https://www.youtube.com/embed/${VideoID}`}
                            title="YouTube Video"
                            onLoad={() => SetIsSpinnerData(false)}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
                <div className="w-full flex justify-end"><Link to="propos" className="flex text-lg items-center text-blue-400 focus:no-underline hover:no-underline">Voir plus des articles<FaArrowRight /></Link></div>
            </section>


            <section className={`w-full relative ${isDark === "dark" ? 'bg-gray-800' : 'bg-gray-100'} p-2  md:max-auto `}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="text-center mb-12">
                        <h2 className={`text-3xl font-bold ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'} mb-4`}>
                            Questions Fréquemment Posées
                        </h2>
                        <p className={`text-xl text-gray-600 ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                            Trouvez des réponses aux questions courantes sur la visite du
                            Burundi
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className={`p-6 rounded-lg ${isDark === "dark" ? 'bg-gray-700' : 'bg-white'} border-2 border-dotted border-blue-700 shadow-sm`}>
                            <div className="flex items-start">
                                <HelpCircle className="h-6 w-6 text-blue-600 mr-3 flex-shrink-0 mt-1" />
                                <div>
                                    <h3 className={`text-lg font-bold mb-2 ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                        Meilleure Période pour Visiter ?
                                    </h3>
                                    <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                        Les saisons sèches (juin-août et décembre-février) sont
                                        idéales pour visiter le Burundi, offrant des températures
                                        confortables et peu de pluie.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={`p-6 rounded-lg ${isDark === "dark" ? 'bg-gray-700' : 'bg-white'} border-2 border-dotted border-blue-700 shadow-sm`}>
                            <div className="flex items-start">
                                <HelpCircle className="h-6 w-6 text-blue-600 mr-3 flex-shrink-0 mt-1" />
                                <div>
                                    <h3 className={`text-lg font-bold mb-2 ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                        Quelles Sont les Options de Transport ?
                                    </h3>
                                    <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                        Le Burundi offre plusieurs options de transport, y compris
                                        les taxis, les bus et les locations de voitures pour
                                        explorer le pays.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={`p-6 rounded-lg ${isDark === "dark" ? 'bg-gray-700' : 'bg-white'} border-2 border-dotted border-blue-700 shadow-sm`}>
                            <div className="flex items-start">
                                <HelpCircle className="h-6 w-6 text-blue-600 mr-3 flex-shrink-0 mt-1" />
                                <div>
                                    <h3 className={`text-lg font-bold mb-2 ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                        Faut-il des Vaccins ?
                                    </h3>
                                    <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                        Il est recommandé de se faire vacciner contre la fièvre
                                        jaune et d'autres maladies selon les conseils des
                                        professionnels de santé.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={`p-6 rounded-lg ${isDark === "dark" ? 'bg-gray-700' : 'bg-white'} border-2 border-dotted border-blue-700 shadow-sm`}>
                            <div className="flex items-start">
                                <HelpCircle className="h-6 w-6 text-blue-600 mr-3 flex-shrink-0 mt-1" />
                                <div>
                                    <h3 className={`text-lg font-bold mb-2 ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                        Quelle Devrait Être la Monnaie ?
                                    </h3>
                                    <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                        La monnaie officielle du Burundi est le franc burundais
                                        (BIF). Les cartes de crédit sont acceptées dans certains
                                        établissements.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ExploiteHomeVisitor