import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useThemes } from "../../../UserContext/UserContext";

const UtilisateurAdminDetail = () => {
    const { id } = useParams();
    const { isDark } = useThemes()
    const navigate = useNavigate();

    const [users, setUsers] = useState({
        username: "",
        first_name: "",
        last_name: "",
        profession: "",
        country: "",
        sexe: "",
        email: "",
    });

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(
                    `https://ttcvbackend.eac-advisor.org/accounts/membres/${id}`
                );
                setUsers(response.data);
            } catch (error) {
                console.error("Erreur de récupération des informations pour l'utilisateur", error);
                setError("Erreur lors de la récupération");
            } finally {
                setIsLoading(false);
            }
        };
        fetchUserDetails();
    }, [id]);

    const handle = (e) => {
        setUsers({
            ...users,
            [e.target.name]: e.target.value,
        });
        setValidationErrors({ ...validationErrors, [e.target.name]: null });
    };

    const getToken = () => {
        return localStorage.getItem("accessToken");
    };

    const axiosInstance = axios.create({
        baseURL: "https://ttcvbackend.eac-advisor.org",
        headers: {
            Authorization: `Bearer ${getToken()}`,
        },
    });

    const validateFields = () => {
        const errors = {};
        if (!users.username) errors.username = "Le nom d'utilisateur est requis.";
        if (!users.email) {
            errors.email = "L'email est requis.";
        } else if (!/\S+@\S+\.\S+/.test(users.email)) {
            errors.email = "L'email est invalide.";
        }
        if (!users.first_name) errors.first_name = "Le prénom est requis.";
        if (!users.last_name) errors.last_name = "Le nom est requis.";
        if (!users.profession) errors.profession = "La profession est requise.";
        if (!users.country) errors.country = "Le pays est requis.";
        if (!users.sexe) errors.sexe = "Le sexe est requis.";

        return errors;
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setValidationErrors(validationErrors);
            return;
        }

        const formData = new FormData();
        Object.keys(users).forEach((key) => {
            formData.append(key, users[key]);
        });

        try {
            await axiosInstance.put(`/accounts/membres/${id}/`, formData);
            alert("L'utilisateur a été mis à jour avec succès !");
            navigate("/admin/dashboard");
        } catch (error) {
            console.error("Erreur de mise à jour", error);
            if (error.response) {
                const errorMessage =
                    error.response.data.detail || JSON.stringify(error.response.data);
                setError(`Erreur: ${errorMessage}`);
            } else if (error.request) {
                setError("Aucune réponse du serveur.");
            } else {
                setError("Erreur dans la configuration de la requête.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <section className={`w-full ${isDark === "dark" ? null : 'bg-gray-100'} p-2 relative md:max-auto`}>
            <div className="flex justify-end rounded-md items-center w-full">
                <div onClick={() => navigate('/admin/users')} className="bg-blue-700 text-white cursor-pointer p-1 w-max">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg>
                </div>
            </div>

            <h1 className="font-bold text-2xl  mb-6">Détail & Modification</h1>
            <div className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} shadow rounded-lg p-6 space-y-6`}>
                {error && <p className="text-red-500 text-center">{error}</p>}
                {Object.entries(validationErrors).map(([key, message]) => (
                    <p key={key} className="text-red-500 text-sm">
                        {message}
                    </p>
                ))}

                <form className="space-y-6" onSubmit={handleUpdate}>
                    {[
                        { label: "Username", name: "username" },
                        { label: "Prénom", name: "first_name" },
                        { label: "Nom", name: "last_name" },
                        { label: "Profession", name: "profession" },
                        { label: "Pays", name: "country" },
                        { label: "Sexe", name: "sexe" },
                        { label: "Email", name: "email" },
                    ].map(({ label, name }) => (
                        <div key={name}>
                            <label className="block  font-semibold mb-2" htmlFor={name}>
                                {label} :
                            </label>
                            <input
                                type="text"
                                className="w-full border bg-transparent border-gray-300 rounded-lg px-4 py-3 focus:outline-none focus:border-blue-500"
                                value={users[name]}
                                onChange={handle}
                                name={name}
                                placeholder={`${label}`}
                            />
                        </div>
                    ))}
                    <div className="flex justify-end space-x-4">
                        <button
                            type="submit"
                            className="px-6 py-2 rounded-lg bg-blue-600 text-white font-medium hover:bg-blue-700 transition duration-200"
                            disabled={isLoading}
                        >
                            {isLoading ? "Chargement..." : "Modifier"}
                        </button>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default UtilisateurAdminDetail;
