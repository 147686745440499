import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

// Icons
import { IoMdHome } from "react-icons/io";
import { IoMdAddCircle } from "react-icons/io";
import { MdAddShoppingCart } from "react-icons/md";
import { IoChatbubbles } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { GiNewspaper } from "react-icons/gi";
import { useThemes } from "../../../UserContext/UserContext";

const SlideBarsAdmin = () => {

    const menus = [
        { id: 1, text: 'Accueil', url: 'dashboard', icon: <IoMdHome size={24} /> },
        { id: 2, text: 'Publication', url: 'publier', icon: <IoMdAddCircle size={24} /> },
        { id: 3, text: 'Publicité', url: 'publiciter', icon: <MdAddShoppingCart size={24} /> },
        { id: 4, text: 'Messages', url: 'message', icon: <IoChatbubbles size={24} /> },
        { id: 5, text: 'Gestion des utilisateurs', url: 'users', icon: <FaUsers size={24} /> },
        { id: 6, text: 'Gestion des publications', url: 'posts', icon: <GiNewspaper size={24} /> }
    ];

    const [selectedMenuId, setSelectedMenuId] = useState(menus[0].id);
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const { isDark, mobile } = useThemes();
    const location = useLocation();

    useEffect(() => {
        const matchedMenu = menus.find(menu => {
            const regex = new RegExp(`^/admin/${menu.url}`);
            return regex.test(location.pathname);
        });

        if (matchedMenu) {
            setSelectedMenuId(matchedMenu.id);
        }
    }, [location.pathname, menus]);

    const handleMenuClick = (id, url) => {
        setSelectedMenuId(id);
        localStorage.setItem('selectedMenuId', id);

        const newUrl = `/admin/${url}`;
        window.location.href = newUrl;
    };

    return (
        <nav className={`${mobile ? 'w-[6em]' : 'w-[20%]'} h-screen border-r border-slate-300 ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'}`}>
            <div>
                <Link to='/admin/dashboard'>
                    <img src='../images/ttcv.png' alt="" className={`${mobile ? 'w-20 h-20' : 'md:w-32 md:h-32 '} w-full h-full`} />
                </Link>
            </div>
            <ul className="p-4">
                {menus.map((item) => (
                    <li key={item.id}>
                        <Link
                            to={item.url}
                            onClick={(e) => {
                                e.preventDefault();
                                handleMenuClick(item.id, item.url);
                            }}
                            className={`flex items-center py-3 ${mobile ? 'justify-center' : ''} ${selectedMenuId === item.id ? 'text-blue-500 bg-gray-200 rounded-lg' : ''}`}
                        >
                            {item.icon}
                            <span className={`${mobile ? 'hidden' : 'text-nowrap'}`}>{item.text}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default SlideBarsAdmin;
