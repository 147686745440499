import React, { useState, useEffect } from 'react'
import Sloger from './Sloger'
import QuiTTCV from './QuiTTCV'
import CultureHomeVisiteur from './CultureHomeVisiteur'
import VoyageVisiorHomme from './VoyageVisiorHomme'
import TourismeVisitorHome from './TourismeVisitorHome'
import ExploiteHomeVisitor from './ExploiteHomeVisitor'
import { useThemes } from '../../../UserContext/UserContext'

function AcceuillComponent() {
  const [mobile, setMobile] = useState(window.innerWidth < 710)
  const { mobileTop } = useThemes()
  useEffect(() => {
    const handleSize = () => {
      setMobile(window.innerWidth < 710);

    };

    window.addEventListener('resize', handleSize);
    return () => {
      window.removeEventListener('resize', handleSize);
    };
  }, []);
  return (
    <div className={`${mobileTop ? 'mt-[6vh]' : 'mt-[12vh]'}  p-2 relative overflow-hidden`}>
      <Sloger />
      <QuiTTCV />
      <CultureHomeVisiteur />
      <VoyageVisiorHomme />
      <TourismeVisitorHome />
      <ExploiteHomeVisitor VideoID="sbDPc11LT70" />
    </div>
  )
}

export default AcceuillComponent