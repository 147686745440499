import React, { useEffect, useState } from 'react'
import { FaFacebook, FaWhatsapp } from 'react-icons/fa'
import { Link } from 'react-router-dom'
function Footer() {
    const [mobile1, SetMobile1] = useState(window.innerWidth < 400)
    useEffect(() => {
        const hundleSize = () => {
            SetMobile1(window.innerWidth < 400)
        }
        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])
    return (
        <Link to='https://www.burundientempsreel.com' target='_blank' className={`bg-[rgba(10,1,67,0.93)] border-t flex  ${mobile1 ? 'flex-col' : ''} w-full text-center justify-center items-center  text-white`}>
            <footer className="w-full text-white" style={{ fontFamily: "cursive" }}>
                {/* Top Section */}
                <div className="bg-teal-700 p-10">
                    <div className="container mx-auto grid sm:grid-cols-3 gap-8 text-left">
                        {/* About Section */}
                        <div>
                            <h2 className="font-bold text-xl mb-4 text-white">À Propos</h2>
                            <ul className="space-y-3 text-gray-100">
                                <li className="hover:text-amber-300 transition-all">
                                    <Link to="propos" className="text-blue-300 hover:text-blue-500">Qui nous sommes ?</Link>
                                </li>
                                <li className="hover:text-amber-300 transition-all">
                                    <Link to="propos" className="text-blue-300 hover:text-blue-500">Qu'est ce que TTCV ?</Link>
                                </li>
                            </ul>
                        </div>

                        {/* Articles Section */}
                        <div>
                            <h2 className="font-bold text-xl mb-4 text-white">Articles</h2>
                            <ul className="space-y-3 text-gray-100">
                                <li className="hover:text-amber-300 transition-all">
                                    <Link to="propos" className="text-blue-300 hover:text-blue-500">Comment publier un article chez TTCV ?</Link>
                                </li>
                                <li className="hover:text-amber-300 transition-all">
                                    <Link to="propos" className="text-blue-300 hover:text-blue-500">Comment recevoir des nouvelles ?</Link>
                                </li>
                                <li className="hover:text-amber-300 transition-all">
                                    <Link to="propos" className="text-blue-300 hover:text-blue-500">Comment commenter des publications ?</Link>
                                </li>
                            </ul>
                        </div>

                        {/* Social Media Section */}
                        <div className="flex flex-col items-center sm:items-start">
                            <h2 className="font-bold text-xl mb-4 text-white">Suivez-nous</h2>
                            <div className="flex space-x-4 text-white">
                                <a
                                    href="https://www.facebook.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-400 hover:text-blue-500 transition-all"
                                >
                                    <FaFacebook size={32} />
                                </a>
                                <a
                                    href="https://www.whatsapp.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="hover:text-green-400 transition-all"
                                >
                                    <FaWhatsapp size={32} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Bottom Section */}
                <div className="bg-gray-900 py-4">
                    <div className="text-center text-gray-300">
                        <h1 className="font-semibold text-lg">
                            Développé par Burundi en Temps Réel
                        </h1>
                        <p className="text-sm mt-2">&copy; 2024 - Tous droits réservés</p>
                    </div>
                </div>
            </footer>
        </Link>
    )
}

export default Footer
