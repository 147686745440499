import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useThemes } from "../../../UserContext/UserContext.js";
import { ToastContainer } from "react-toastify";
import Footer from "../Footer/Footer.js";
import { CustomProvider } from "rsuite";
import NavBarsVisiteur from "../NavBarsVisiteur/NavBarsVisiteur.js";

const LayoutVisiteur = () => {
    const { isDark, getWidth, HundlwScrollTop, isScroll } = useThemes();
    const [isLoading, setisLoading] = useState(false);

    useEffect(() => {
        setisLoading(true);
        const recordVisit = async () => {
            await axios.post("https://ttcvbackend.eac-advisor.org/posts/api/visit/");
        };
        recordVisit();
    }, []);

    console.log("isLoading", isLoading)
    return (
        <>

            <CustomProvider theme={isDark}>
                <div
                    className="h-[3px] fixed  bg-[#666feb] top-0   left-0 z-[2000]"
                    style={{
                        width: `${getWidth * 100}%`,
                    }}
                ></div>
                <NavBarsVisiteur />
                <div className="overflow-x-hidden">
                    <Outlet />
                </div>
                <div
                    onClick={HundlwScrollTop}
                    className={`${isScroll
                        ? "opacity-100 pointer-events-auto"
                        : "opacity-0 pointer-events-none"
                        } transition-all fixed w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer right-6 top-[90%] bg-[#617a5c] hover:bg-[#666feb] duration-75 text-white z-[30]`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-capslock-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1z" />
                    </svg>
                </div>
                <Footer />
                <ToastContainer />
            </CustomProvider>
        </>
    );
};

export default LayoutVisiteur;
