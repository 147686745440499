/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import "apexcharts/dist/apexcharts.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "rsuite/dist/rsuite.min.css";
import "aos/dist/aos.css";
import "react-multi-carousel/lib/styles.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "leaflet-geosearch/dist/geosearch.css";
import "leaflet/dist/leaflet.css";
import 'react-multi-carousel/lib/styles.css';


import Aos from "aos";
import { useThemes } from "./UserContext/UserContext";
import { CustomProvider } from "rsuite";
import AcceuillComponent from "./Compenent/Visiteurs/Acceuil/AcceuillComponent";
import { useEffect } from "react";
import CultureVisit from "./Compenent/Visiteurs/CultureVisit/CultureVisit";
import CultureVisitDetail from "./Compenent/Visiteurs/CultureVisit/CultureVisitDetail";
import ToursmeVisitor from "./Compenent/Visiteurs/ToursmeVisitor/ToursmeVisitor";
import ToursmeVisitorDetail from "./Compenent/Visiteurs/ToursmeVisitor/ToursmeVisitorDetail";
import VoyageVisitor from "./Compenent/Visiteurs/VoyageVisitor/VoyageVisitor";
import VoyageVisitorDetail from "./Compenent/Visiteurs/VoyageVisitor/VoyageVisitorDetail";
import Messages from "./Compenent/Visiteurs/Messages/Messages";
import Propos from "./Compenent/Visiteurs/Propos/Propos";
import ConfirmePassWord from "./Compenent/Visiteurs/Formaire/ConfirmePassWord";
import LoginAdmin from "./Compenent/Visiteurs/Formaire/LoginAdmin";
import ToursmeVisitorPublier from "./Compenent/Visiteurs/ToursmeVisitor/ToursmeVisitorPublier";
import CulturePublier from "./Compenent/Visiteurs/CultureVisit/CulturePublier";
import VoyageVisitorPublier from "./Compenent/Visiteurs/VoyageVisitor/VoyageVisitorPublier";
import Compte from "./Compenent/Visiteurs/Pages/CompteUtilisateur";
import LayoutVisiteur from "./Compenent/Visiteurs/LayoutVisiteur/LayoutVisiteur";
import LayoutAdmin from "./Compenent/Admin/LayoutAdmin/LayoutAdmin";
import AcceuilAdmin from "./Compenent/Admin/AcceuilAdmin/AcceuilAdmin";
import PublicAdmin from "./Compenent/Admin/PublicAdmin/PublicAdmin";
import PubliciteList from "./Compenent/Admin/PublicAdmin/PubliciteList";
import MessageAdmin from "./Compenent/Admin/MessageAdmin/MessageAdmin";
import UtilisateurAdmin from "./Compenent/Admin/UtilisateurAdmin/UtilisateurAdmin";
import UtilisateurAdminDetail from "./Compenent/Admin/UtilisateurAdmin/UtilisateurAdminDetail";
import PublicationAdmin from "./Compenent/Admin/PublicationAdmin/PublicationAdmin";
import PublicationAdminDetail from "./Compenent/Admin/PublicationAdmin/PublicationAdminDetail";
import Nvalide from "./Compenent/Admin/AcceuilAdmin/Nvalide";
import PubliciteListModifier from "./Compenent/Admin/PublicAdmin/PubliciteListModifier";
import AdminCompte from "./Compenent/Admin/AdminCompte/AdminCompte";

function App() {
  const {
    isGoSite,

    SetOpenModal,
    isVisitor,
    isDarVisitLoggined,
    SetIsLogin
  } = useThemes();

  useEffect(() => {
    const initAos = () => {
      if (typeof Aos !== "undefined") {
        Aos.init({
          once: true,
          duration: 1000,
          easing: "ease-out-cubic",
        });
      }
    };

    const timer = setTimeout(initAos, 500);
    const hundleLoadWindows = () => {
      //    on met la page spinner
    };
    const hundleClick = () => {
      SetOpenModal(false);
    };
    window.addEventListener("load", () => {
      hundleLoadWindows();
    });
    window.addEventListener("click", () => {
      hundleClick();
    });

    return () => {
      clearTimeout(timer);
      window.removeEventListener("load", () => {
        hundleLoadWindows();
      });
      window.removeEventListener("click", () => {
        hundleClick();
      });
    };
  }, []);








  localStorage.setItem("csrfToken", "ZBFlpxkF-CizJgNJoko90umSBMkNYSlQ5x7o");

  const tokenExist = localStorage.getItem("accessToken")

  useEffect(() => {
    if (tokenExist) {
      SetIsLogin(true)
    } else {
      SetIsLogin(false)
    }
  }, [])



  return isVisitor ? (
    <Routes>
      <Route path="/" element={<LayoutVisiteur />}>
        <Route path="/" Component={AcceuillComponent} />

        <Route path="/culture" element={<CultureVisit categorie="Culture" />} />
        <Route path="/culture/detail/:id" Component={CultureVisitDetail} />
        <Route path="/culture/publier" Component={CulturePublier} />

        <Route path="/voyage" element={<VoyageVisitor categorie="Voyage" />} />
        <Route path="/voyage/detail/:id" element={<VoyageVisitorDetail />} />
        <Route path="/voyage/publier" element={<VoyageVisitorPublier />} />

        <Route path="/toursme" element={<ToursmeVisitor categorie="Tourisme" />} />
        <Route path="/toursme/detail/:id" element={<ToursmeVisitorDetail />} />
        <Route path="/toursme/publier" element={<ToursmeVisitorPublier />} />

        <Route path="/message" element={<Messages />} />
        <Route path="/password-reset-confirm/:uid/:token" element={<ConfirmePassWord />} />
        <Route path="/propos" element={<Propos />} />

        <Route path="/Compte" element={<Compte />} />

      </Route>

      <Route path="/loginA" element={<LoginAdmin />} />

      <Route path="/admin" element={<LayoutAdmin />}>
        <Route path="dashboard" element={<AcceuilAdmin />} />
        <Route path="dashboard/valide" element={<Nvalide />} />
        <Route path="publier" element={<PublicAdmin />} />
        <Route path="publiciter" element={<PubliciteList />} />
        <Route path="publiciter/:id" element={<PubliciteListModifier />} />
        <Route path="message" element={<MessageAdmin />} />
        <Route path="users" element={<UtilisateurAdmin />} />
        <Route path="users/:id" element={<UtilisateurAdminDetail />} />
        <Route path="posts" element={<PublicationAdmin />} />
        <Route path="posts/:id" element={<PublicationAdminDetail />} />
        <Route path="adminCompte" element={<AdminCompte />} />
      </Route>

    </Routes>

  ) : (
    <>C'est null</>
  );
}

export default App;
