/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TranslateComponent from "../../TranslateComponent/TranslateComponent";
import { useThemes } from "../../../UserContext/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap";
import { Drawer, Modal, Popover, Whisper } from "rsuite";
import Login from "../Formaire/Login";
import Register from "../Formaire/Register";
import ForgetPassword from "../Formaire/ForgetPassword";
import axios from "axios";
import { toast } from "react-toastify";
gsap.registerPlugin(ScrollTrigger);

function NavBarsVisiteur() {
  const {
    isDark,
    toggleDarkMode,
    isDarVisitLoggined,
    SetisGoingToSite,
    isGoingToSite,
    mobileTop,
    setOpenLogin,
    openLogin,
    openRegi, setOpenRegi,
    openforget, setopenforget,
    isLogin,
    SetIsLogin,
    username, setUsername
  } = useThemes();
  const { pathname } = useLocation();
  const navig = useNavigate();
  const acceuil = /^\/.*/;
  const culture = /^\/culture.*/;
  const toursme = /^\/toursme.*/;
  const voyage = /^\/voyage.*/;
  const message = /^\/message.*/;

  const propos = /^\/propos.*/;


  useEffect(() => {
    const showAnim = gsap
      .from(".scrollToBottomNavBars", {
        yPercent: -100,
        paused: true,
        duration: 0.2,
      })
      .progress(1);

    ScrollTrigger.create({
      start: "top top",
      end: "max",
      onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse();
      },
    });
  }, []);



  const [mobile2, setMobile2] = useState(window.innerWidth < 806)
  const [mobile1, setMobile1] = useState(window.innerWidth < 444)
  const [mobile3, setMobile3] = useState(window.innerWidth < 322)
  const [isOpened, setisOpened] = useState(false)

  useEffect(() => {
    const handleSize = () => {
      setMobile2(window.innerWidth < 806);
      setMobile1(window.innerWidth < 444);
      setMobile3(window.innerWidth < 322);
      setisOpened(false)
    };

    window.addEventListener('resize', handleSize);
    return () => {
      window.removeEventListener('resize', handleSize);
    };
  }, []);









  const navigate = useNavigate();

  const token = localStorage.getItem("accessToken")


  const checkLoggedInUser = async () => {
    try {

      if (token) {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          "https://ttcvbackend.eac-advisor.org/accounts/user/",
          config
        );
        console.log("response", response)

        SetIsLogin(true);
        setUsername(response.data.username);
      } else {
        SetIsLogin(false);
        setUsername("");
      }
    } catch (error) {
      SetIsLogin(false);
      setUsername("");
    }
  };

  useEffect(() => {
    checkLoggedInUser();
  }, []);

  const handleLogout = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        await axios.post(
          "https://ttcvbackend.eac-advisor.org/accounts/token/refresh/",
          { refresh: refreshToken }
        );
        toast.success("Déconnexion réussie ");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        SetIsLogin(false);
        setUsername("");
        navigate('/');

      }
    } catch (error) {
      toast.error("Échec de la déconnexion !");
    }
  };





  return (
    <div
      className={`border-b  fixed scrollToBottomNavBars z-[200] border-[#3d8fdb] w-full ${mobileTop ? 'h-[7vh]' : 'h-[12vh]'}   ${isDark === "dark" ? "bg-[#0F172B]" : "bg-white"
        }  ${isDarVisitLoggined === "dark" ? "bg-gray-800" : "bg-white"
        } overflow-hidden`}
    >
      <div className={`flex justify-between  items-center h-full w-full`}>
        <div
          onClick={() => navig("/")}
          className="flex justify-center items-center font-bold cursor-pointer"
        >
          <div className="flex items-center justify-start ">
            <img
              src="../images/ttcv.png"
              alt=""
              className={`${mobileTop ? 'w-14 h-14' : 'w-24 h-24'} object-cover`}
            />
          </div>
          <div className={`font-bold mx-4 font-serif ${mobileTop ? 'text-[20px]' : 'text-[30px]'} text-[#3d8fdb]  uppercase`}>
            T.T.C.V
          </div>
        </div>
        <div className="flex flex-row  text-sm">
          <div className={`text-lg items-center ${mobile2 ? 'hidden' : 'flex'} ${mobileTop ? 'space-x-2' : 'space-x-6'}`}>
            <div
              className={`first-letter:uppercase cursor-pointer text-lg font-bold ${acceuil.test(pathname) &&
                !toursme.test(pathname) &&
                !culture.test(pathname) &&
                !voyage.test(pathname) &&
                !message.test(pathname) &&
                !propos.test(pathname)
                ? "text-[#666feb]"
                : null
                } ${mobileTop ? 'px-2' : 'px-4'} py-2`}
              onClick={() => { setisOpened(false); navig("/") }}
            >

              Accueil
            </div>

            <div
              className={`first-letter:uppercase  cursor-pointer text-lg font-bold ${culture.test(pathname) ? "text-[#666feb]" : null
                } ${mobileTop ? 'px-2' : 'px-4'} py-2`}
              onClick={() => { setisOpened(false); navig("/culture") }}
            >
              culture
            </div>
            <div
              className={`first-letter:uppercase px-2 py-1 cursor-pointer text-lg font-bold ${voyage.test(pathname) ? "text-[#666feb]" : null
                } ${mobileTop ? 'px-2' : 'px-4'} py-2`}
              onClick={() => { setisOpened(false); navig("/voyage") }}
            >
              Voyage
            </div>

            <div
              className={`first-letter:uppercase px-2 py-1 cursor-pointer text-lg font-bold ${toursme.test(pathname) ? "text-[#666feb]" : null
                } ${mobileTop ? 'px-2' : 'px-4'} py-2`}
              onClick={() => { setisOpened(false); navig("/toursme") }}
            >
              Tourisme
            </div>

            <div
              className={`first-letter:uppercase px-2 py-1 cursor-pointer text-lg font-bold ${message.test(pathname) ? "text-[#666feb]" : null
                } ${mobileTop ? 'px-2' : 'px-4'} py-2`}
              onClick={() => { setisOpened(false); navig("/message") }}
            >
              Messages
            </div>
            <div
              className={`first-letter:uppercase text-nowrap px-2 py-1 cursor-pointer text-lg font-bold ${propos.test(pathname) ? "text-[#666feb]" : null
                } ${mobileTop ? 'px-2' : 'px-4'} py-2`}
              onClick={() => { setisOpened(false); navig("/propos") }}
            >
              à propos
            </div>

          </div>

          <TranslateComponent />
          {isLogin ? (
            <Whisper
              trigger='click'
              placement="bottom"
              speaker={
                <Popover>
                  <div onClick={() => navigate("/Compte")} className="flex cursor-pointer justify-start rounded-md items-center text-black bg-gray-200 p-2 uppercase font-serif font-extrabold ">{username && username}</div>

                  <div onClick={handleLogout} className="flex justify-center mt-1 rounded-md cursor-pointer items-center text-black bg-gray-200 p-2 uppercase ">Déconnexion</div>
                </Popover>
              }
            >
              <div className="w-10 rounded-full cursor-pointer h-10 border flex justify-center items-center uppercase text-2xl font-serif font-extrabold text-blue-600">{username && username.split('')[0]}</div>
            </Whisper>
          ) : (
            <div
              className={`relative p-1 ${mobile3 ? 'ml-0' : 'ml-2'} h-max`}
              onClick={() => setOpenLogin(true)}
            >
              <div className="p-2 bg-green-100 hover:bg-green-300 cursor-pointer text-[#666feb] rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-person-fill-add"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                  <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                </svg>
              </div>
            </div>
          )}


          <div onClick={toggleDarkMode} className={`relative p-1 ${mobile3 ? 'ml-0' : 'ml-2'} h-max`}>
            {isDark === "dark" ? (
              <div className="p-2 bg-green-100 hover:bg-green-300 cursor-pointer text-[#666feb] rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-brightness-high-fill animate-spin"
                  viewBox="0 0 16 16"
                >
                  <path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
                </svg>
              </div>
            ) : (
              <div className="p-2 bg-green-100 hover:bg-green-300 cursor-pointer text-[#666feb] rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-moon-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" />
                </svg>
              </div>
            )}
          </div>
          {mobile2 && (
            <div className={`relative p-1 ${mobile3 ? 'ml-0' : 'ml-2'} h-max`}>
              <div onClick={() => setisOpened(true)} className="p-2 bg-green-100 hover:bg-green-300 cursor-pointer text-[#666feb] rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
                </svg>
              </div>
            </div>
          )}

        </div>
      </div >


      <Drawer open={isOpened} size={mobile1 ? 'full' : 'xs'} placement="right" onClose={() => setisOpened(false)}>
        <Drawer.Header>
          <Drawer.Title>Menu de navigation</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div className="flex w-full justify-around flex-col h-[40%] items-start">
            <div
              className={`first-letter:uppercase cursor-pointer text-lg font-bold ${acceuil.test(pathname) &&
                !toursme.test(pathname) &&
                !culture.test(pathname) &&
                !voyage.test(pathname) &&
                !message.test(pathname) &&
                !propos.test(pathname)
                ? "text-[#666feb]"
                : null
                } ${mobileTop ? 'px-2' : 'px-4'} py-2`}
              onClick={() => { setisOpened(false); navig("/") }}
            >
              Acceuil
            </div>
            <div
              className={`first-letter:uppercase cursor-pointer text-lg font-bold ${culture.test(pathname) ? "text-[#666feb]" : null
                } ${mobileTop ? 'px-2' : 'px-4'} py-2`}
              onClick={() => { setisOpened(false); navig("/culture") }}
            >
              culture
            </div>
            <div
              className={`first-letter:uppercase cursor-pointer text-lg font-bold ${voyage.test(pathname) ? "text-[#666feb]" : null
                } ${mobileTop ? 'px-2' : 'px-4'} py-2`}
              onClick={() => { setisOpened(false); navig("/voyage") }}
            >
              Voyage
            </div>

            <div
              className={`first-letter:uppercase cursor-pointer text-lg font-bold ${toursme.test(pathname) ? "text-[#666feb]" : null
                } ${mobileTop ? 'px-2' : 'px-4'} py-2`}
              onClick={() => { setisOpened(false); navig("/toursme") }}
            >
              Tourisme
            </div>
            <div
              className={`first-letter:uppercase cursor-pointer text-lg font-bold ${message.test(pathname) ? "text-[#666feb]" : null
                } ${mobileTop ? 'px-2' : 'px-4'} py-2`}
              onClick={() => { setisOpened(false); navig("/message") }}
            >
              Messages
            </div>
            <div
              className={`first-letter:uppercase cursor-pointer text-lg font-bold ${propos.test(pathname) ? "text-[#666feb]" : null
                } ${mobileTop ? 'px-2' : 'px-4'} py-2`}
              onClick={() => { setisOpened(false); navig("/propos") }}
            >
              À propos
            </div>
          </div>
        </Drawer.Body> Register
      </Drawer>
      <Drawer size='full' open={openLogin} placement="bottom" onClose={() => setOpenLogin(false)}>
        <Drawer.Header>
          <Drawer.Title className="text-center uppercase font-serif">Formaire de connexion</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Login />
        </Drawer.Body>
      </Drawer>
      <Drawer size='full' open={openRegi} placement="bottom" onClose={() => setOpenRegi(false)}>
        <Drawer.Header>
          <Drawer.Title className="text-center uppercase font-serif">Formaire d'inscription</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Register />
        </Drawer.Body>
      </Drawer>
      <Drawer size='full' open={openforget} placement="left" onClose={() => setopenforget(false)}>
        <Drawer.Header>
          <Drawer.Title className="text-center uppercase font-serif">Formaire de votre email</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <ForgetPassword />
        </Drawer.Body>
      </Drawer>
    </div >
  );
}

export default NavBarsVisiteur;
