// MessageForm.js
import React, { useState } from "react";
import axios from "axios";
import { useThemes } from "../../../UserContext/UserContext";

const Messages = () => {
    const { isDark,mobileTop } = useThemes()
    const [email, setEmail] = useState("");
    const [content, setContent] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(
                "https://ttcvbackend.eac-advisor.org/messages/messages/",
                {
                    email,
                    content,
                }
            );
            setSuccess(true);
            setEmail("");
            setContent("");
        } catch (err) {
            setError("Erreur lors de l'envoi du message");
        }
    };

    return (
        <>
            <div className={`w-full ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} p-2 ${mobileTop ? 'mt-[6vh]' : 'mt-[12vh]'}   relative  md:max-auto `}>

                <form
                    onSubmit={handleSubmit}
                    className={`flex p-6  ${isDark === "dark" ? 'bg-gray-600' : 'bg-white shadow-2xl'}   rounded-lg shadow-lg mt-16 mb-16 space-y-10 flex-col items-center`}
                >
                    <div className={`font-bold md:text-2xl ${isDark === "dark" ? 'text-gray-100' : 'text-gray-900'}`}>
                        Envoyer vos messages ici
                    </div>
                    <input
                        type="email"
                        placeholder="Votre adresse email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className={`border-2  border-blue-500 w-full p-3 sm:w-1/2 bg-transparent rounded-md focus:outline-none outline-none ${isDark === "dark" ? 'text-gray-100' : 'text-gray-900'} focus:border-blue-400`}
                    />
                    <textarea
                        placeholder="Votre message ..."
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        required
                        className={`border-2 border-blue-500 w-full sm:w-1/2 bg-transparent rounded-md focus:outline-none outline-none focus:border-blue-400 min-h-[10em] max-h-[20em] ${isDark === "dark" ? 'text-gray-100' : 'text-gray-900'} p-3`}
                    />
                    <button type="submit" className="bg-blue-500 w-40 h-11  text-white">
                        Envoyer
                    </button>
                </form>
                {success && <p>Message envoyé avec succès !</p>}
                {error && <p>{error}</p>}
            </div>
        </>
    );
};

export default Messages;
