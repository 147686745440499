import React from 'react'
import { useThemes } from '../../../UserContext/UserContext'

import {
    ArrowRight,
    Calendar,
    MapPin,
    Users,
    Plane,
    Camera,
    Utensils,
    Globe,
    Heart,
    Target,
} from "lucide-react";

function QuiTTCV() {
    const { isDark } = useThemes()


    return (
        <div className="pt-16">
            <section data-aos="fade-down" className={`w-full relative ${isDark === "dark" ? 'bg-gray-800' : 'bg-gray-100'} p-2  md:max-auto `}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div data-aos="fade-down" className="text-center mb-12">
                        <div className={`sm:text-3xl text-lg font-bold ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'} mb-4 font-serif text-blue-700`}>Qui est TTCV ?</div>
                        <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'} sm:text-xl`}>
                            Vision des Voyages Touristiques et Culturels
                        </p>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                        <div className="text-center w-full border border-t-4 p-2 rounded rounded-t-2xl border-green-600 ">
                            <Globe className="h-16 w-16 text-emerald-600 mx-auto mb-4" />
                            <div className={`sm:text-xl font-bold mb-2 ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>Notre Mission</div>
                            <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                Promouvoir et préserver le patrimoine culturel du Burundi tout
                                en offrant des expériences de voyage exceptionnelles.
                            </p>
                        </div>
                        <div className="text-center w-full border border-t-4 p-2 rounded rounded-t-2xl border-blue-600 ">
                            <Heart className="h-16 w-16 text-blue-600 mx-auto mb-4" />
                            <div className={`text-xl font-bold  ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'} mb-2`}>Nos Valeurs</div>
                            <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                L'authenticité, la durabilité et l'autonomisation des
                                communautés guident tout ce que nous faisons.
                            </p>
                        </div>
                        <div className="text-center w-full border border-t-4 p-2 rounded rounded-t-2xl border-gray-600 ">
                            <Target className="h-16 w-16 text-gray-600 mx-auto mb-4" />
                            <div className={`text-xl font-bold ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'} mb-2`}>Notre Vision</div>
                            <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                Faire du Burundi une destination de choix pour le tourisme
                                culturel en Afrique de l'Est.
                            </p>
                        </div>
                    </div>
                </div>
            </section>



            {/* Section Héro */}
            <section className="relative  sm:h-[90vh]">
                <div className="absolute inset-0">
                    <img
                        src="images/slogas.jpg"
                        alt="Paysage du Burundi"
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                </div>
                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
                    <div className="text-white">
                        <div className="sm:text-4xl text-2xl md:text-6xl font-bold mb-6">
                            Découvrez la Beauté du Burundi
                        </div>
                        <p className="sm:text-xl md:text-2xl mb-8 max-w-2xl">
                            Découvrez la chaleur de ce joyau caché de l'Afrique de l'Est à
                            travers sa riche culture, ses paysages à couper le souffle et ses
                            traditions vibrantes.
                        </p>
                        <a
                            href="/messages"
                            className="inline-flex items-center sm:px-6 p-1 max-sm:mb-2  sm:py-3 border-2 border-emerald-500 text-emerald-500 hover:bg-emerald-500 hover:text-white rounded-lg text-lg font-medium transition-colors"
                        >
                            Explorer Plus
                            <ArrowRight className="ml-2 h-5 w-5" />
                        </a>
                    </div>
                </div>
            </section>

            {/* Section Services */}
            <section data-aos="fade-down-left" className={`w-full relative ${isDark === "dark" ? 'bg-gray-800' : 'bg-gray-100'} p-2  md:max-auto `}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className={`text-3xl font-bold text-center ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'} mb-12`}>Nos Services</div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className={`${isDark === "dark" ? 'bg-gray-700' : 'bg-white'} rounded-lg p-8 `}>
                            <Plane className="h-12 w-12 text-emerald-600 mb-4" />
                            <div className="text-xl font-bold mb-2">
                                Planification de Voyage
                            </div>
                            <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                Itinéraires personnalisés et arrangements de voyage pour une
                                expérience inoubliable au Burundi.
                            </p>
                        </div>
                        <div className={`${isDark === "dark" ? 'bg-gray-700' : 'bg-white'} rounded-lg p-8 `}>
                            <Camera className="h-12 w-12 text-emerald-600 mb-4" />
                            <div className="text-xl font-bold mb-2">Visites Culturelles</div>
                            <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                Expériences immersives mettant en valeur le riche patrimoine et
                                les traditions du Burundi.
                            </p>
                        </div>
                        <div className={`${isDark === "dark" ? 'bg-gray-700' : 'bg-white'} rounded-lg p-8 `}>
                            <Utensils className="h-12 w-12 text-emerald-600 mb-4" />
                            <div className="text-xl font-bold mb-2">Expériences Culinaires</div>
                            <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                Visites gastronomiques authentiques et cours de cuisine mettant
                                en avant la cuisine locale.
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            {/* Sections en vedette */}

            <section data-aos="fade-down-right" className={`w-full relative ${isDark === "dark" ? 'bg-gray-800' : 'bg-gray-100'} p-2  md:max-auto `}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                        <div className={`${isDark === "dark" ? 'bg-gray-700' : 'bg-white'} rounded-lg p-8 text-center`}>
                            <Calendar className="h-12 w-12 text-emerald-600 mx-auto mb-4" />
                            <div className="text-xl font-bold mb-2">Événements à Venir</div>
                            <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                Découvrez des festivals culturels, des événements musicaux et
                                des cérémonies traditionnelles.
                            </p>
                        </div>
                        <div className={`${isDark === "dark" ? 'bg-gray-700' : 'bg-white'} rounded-lg p-8 text-center`}>
                            <MapPin className="h-12 w-12 text-emerald-600 mx-auto mb-4" />
                            <div className="text-xl font-bold mb-2">
                                Attractions Touristiques
                            </div>
                            <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                Explorez les merveilles naturelles et les sites historiques à
                                travers le Burundi.
                            </p>
                        </div>
                        <div className={`${isDark === "dark" ? 'bg-gray-700' : 'bg-white'} rounded-lg p-8 text-center`}>
                            <Users className="h-12 w-12 text-emerald-600 mx-auto mb-4" />
                            <div className="text-xl font-bold mb-2">Culture Locale</div>
                            <p className={` ${isDark === "dark" ? 'text-gray-200' : 'text-gray-600'}`}>
                                Découvrez les riches traditions et coutumes du peuple burundais.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
}

export default QuiTTCV