import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Chart from "react-apexcharts";
import { useThemes } from "../../../UserContext/UserContext";

const AcceuilAdmin = () => {
    const { isDark } = useThemes()
    const [culture, setCulture] = useState([]);
    const [voyage, setVoyage] = useState([]);
    const [tourisme, setTourisme] = useState([]);
    const [visit, setVisit] = useState({});
    const [users, setUsers] = useState([]);
    const [adds, setAdds] = useState([]);
    const [valide, setValide] = useState([]);

    const [nonvalide, setNonvalide] = useState([]);
    const [deja, setDeja] = useState([]);
    const [posti, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://ttcvbackend.eac-advisor.org/posts/posts/");
                const filteredItems = response.data.filter(item => !item.is_deleted);

                setValide(filteredItems.filter(item => item.is_valid === true));
                setNonvalide(filteredItems.filter(item => item.is_valid === false));
                setDeja(filteredItems.filter(item => item.is_deleted === true));

                const groupedData = filteredItems.reduce((acc, post) => {
                    const date = new Date(post.created).toLocaleDateString();
                    acc[date] = (acc[date] || 0) + 1;
                    return acc;
                }, {});

                setPosts(Object.entries(groupedData).map(([date, posts]) => ({ date, posts })));

                const [categoriesResponse, statsResponse, usersResponse, addsResponse] = await Promise.all([
                    axios.get("https://ttcvbackend.eac-advisor.org/posts/posts/"),
                    axios.get("https://ttcvbackend.eac-advisor.org/posts/api/stats/"),
                    axios.get("https://ttcvbackend.eac-advisor.org/accounts/membres"),
                    axios.get("https://ttcvbackend.eac-advisor.org/posts/adds/"),
                ]);

                setVisit(statsResponse.data);
                setUsers(usersResponse.data);
                setAdds(addsResponse.data.filter(item => !item.is_deleted));

                const categories = ["CULTURE", "VOYAGE", "TOURISME"];
                categories.forEach(category => {
                    const filteredCategory = categoriesResponse.data.filter(
                        item => item.is_valid && !item.is_deleted && item.category === category
                    );
                    if (category === "CULTURE") setCulture(filteredCategory);
                    if (category === "VOYAGE") setVoyage(filteredCategory);
                    if (category === "TOURISME") setTourisme(filteredCategory);
                });

            } catch (error) {
                console.error("Erreur lors de la récupération des données", error);
                setError("Erreur de chargement");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const totalArticles = culture.length + voyage.length + tourisme.length;

    const chartOptions = {
        chart: {
            type: "bar",
        },
        xaxis: {
            categories: posti.map(post => post.date),
        },
        dataLabels: {
            enabled: false,
        },
    };

    const chartSeries = [
        {
            name: "Nombre d'articles",
            data: posti.map(post => post.posts),
        },
    ];

    return (
        <section className={`p-6 ${isDark === "dark" ? null : 'bg-gray-100'}`}>
            <div className="font-bold md:text-2xl mb-5">Les activités en total</div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-7">
                <DashboardCard isDark={isDark} title="Total Articles" count={totalArticles} />
                <DashboardCard isDark={isDark} title="Articles : Culture" count={culture.length} />
                <DashboardCard isDark={isDark} title="Articles : Voyage" count={voyage.length} />
                <DashboardCard isDark={isDark} title="Articles : Tourisme" count={tourisme.length} />
                <DashboardCard isDark={isDark} title="Articles : valide" count={valide.length} />
                <DashboardCard isDark={isDark} title="Articles : non valide" count={nonvalide.length}>
                    <Link to="valide" className="bg-blue-500 text-white px-2 w-24 h-8">
                        voir plus
                    </Link>
                </DashboardCard>
                <DashboardCard isDark={isDark} title="Articles : supprimé" count={deja.length} />
                <DashboardCard isDark={isDark} title="Publicité en cours:" count={adds.length} />
                <DashboardCard isDark={isDark} title="Les utilisateurs inscrits :" count={users.length} />
                <DashboardCard isDark={isDark} title="Visite du site : dans 24 heures" count={visit.last_24_hours || 0} />
                <DashboardCard isDark={isDark} title="Visite du site : 7 derniers jours" count={visit.last_7_days || 0} />
                <DashboardCard isDark={isDark} title="Visite du site : 30 derniers jours" count={visit.last_30_days || 0} />
                <DashboardCard isDark={isDark} title="Visite du site : par une année" count={visit.last_year || 0} />
            </div>
            <div className="font-bold md:text-2xl">Les graphiques</div>
            <div className="w-full mt-10">
                <div className="font-bold ml-4 md:text-2xl">Le nombre des Articles publier par date</div>
                <Chart options={chartOptions} series={chartSeries} type="bar" style={{ width: '100%' }} height={300} />
            </div>
        </section>
    );
};

const DashboardCard = ({ title, count, children, isDark }) => (
    <div className={`flex items-center justify-center p-3 rounded-lg shadow-lg flex-col ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} w-full`}>
        <p className="font-bold">{title}</p>
        <div className="sm:text-[20px] text-[18px]">{count}</div>
        {children}
    </div>
);

export default AcceuilAdmin;
